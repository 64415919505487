import React from "react";
import { Button, Card } from "antd";
import { setModalType } from "../../../Redux/Slices/modal";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import dataControl from "../../../Services/dataControl";




export const LogoutConfirmModal = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()


    return <Card title="Are you sure to delete?"
                 style={ { width: 370, textAlign: "center", display: "flex", flexDirection: "column" } }>

        <div style={ { width: "100%", display: "flex", justifyContent: "center" } }>
            <Button
                type="default"
                onClick={ () => dispatch( setModalType( null ) ) }
                style={ { marginRight: "30px" } }
            >Cancel</Button>
            <Button
                type="primary"
                onClick={ () => {
                    dataControl.removeToken()
                    dispatch( setModalType( null ) )
                    navigate( "/auth" )
                } }
            >Confirm</Button>
        </div>
    </Card>
}

