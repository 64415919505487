import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Card, Space, Select, Checkbox } from 'antd';
import { setModalType } from "../../../Redux/Slices/modal";
import { addContent, addDescriptionContent, editDescriptionContent } from "../../../Redux/Slices/Content/asyncThunk";

const { TextArea } = Input
const MyFormItemContext = React.createContext( [] );

function toArr( str ) {
    return Array.isArray( str ) ? str : [ str ];
}

const MyFormItemGroup = ( { prefix, children } ) => {
    const prefixPath = React.useContext( MyFormItemContext );
    const concatPath = React.useMemo( () => [ ...prefixPath, ...toArr( prefix ) ], [ prefixPath, prefix ] );
    return <MyFormItemContext.Provider value={ concatPath }>{ children }</MyFormItemContext.Provider>;
};
const MyFormItem = ( { name, ...props } ) => {
    const prefixPath = React.useContext( MyFormItemContext );
    const concatName = name !== undefined ? [ ...prefixPath, ...toArr( name ) ] : undefined;
    const getRules = ( name = "" ) => {
        return [ { required: true, message: `This field cannot be left blank` } ]
    }
    return <Form.Item rules={ getRules( concatName[ 0 ] ) } name={ concatName } { ...props } />;
};


export const EditContentDescriptionData = () => {
    const dispatch = useDispatch()
    const description_id = useSelector( state => state.modal.transfer_id )
    const page_id = useSelector( state => state.modal.process_type )
    const allContent = useSelector( state => state.content.all )
    const selected_page = allContent.filter( content => content.id === page_id )[ 0 ]
    const selected_description = selected_page?.Descriptions?.filter( description => description.id === description_id )[ 0 ]
    const selected = useSelector( state => state.languages.selected )
    const [ inLive, setLiveState ] = useState( null )

    const live_switcher = () => {
        let can_show = false
        for ( let prop in selected_description.title ) {
            if ( !selected_description.title[ prop ] && prop !== "ru" ) {
                can_show = true
            }
        }
        for ( let prop in selected_description.text ) {
            if ( !selected_description.text[ prop ] && prop !== "ru" ) {
                can_show = true
            }
        }
        return can_show
    }

    const onFinish = ( value ) => {
        const data = {}
        if ( inLive !== null ) {
            data.can_show = inLive
        }
        for ( let prop in value ) {
            if ( value[ prop ] ) {
                data[ prop ] = value[ prop ]
            }
        }

        if(Object.keys(data).length  !== 0 && description_id){
            dispatch( editDescriptionContent( { data, page_id, description_id, language: selected } ) )
            dispatch( setModalType( null ) )
        }
    };


    return <Card
        style={ { position: "relative", width: 370, textAlign: "center", display: "flex", flexDirection: "column" } }
        title={ `Edit Description: ${ selected.toUpperCase() }` }
        extra={ <Checkbox
            disabled={ live_switcher() }
            defaultChecked={ selected_description.can_show }
            onChange={ ( e ) => setLiveState( e.target.checked ) }
        >
            In Live
        </Checkbox> }
    >
        <Form
            initialValues={ {
                "title": selected_description.title[ selected ],
                "text": selected_description.text[ selected ],
            } }
            name="form_item_path" layout="vertical" onFinish={ onFinish }>
            <MyFormItem name="title" label="Title">
                <TextArea rows={3} placeholder="Title"/>
            </MyFormItem>
            <MyFormItem name="text" label="Text">
                <TextArea rows={6} placeholder="Description"/>
            </MyFormItem>

            <div style={ { width: "100%", display: "flex", justifyContent: "center" } }>
                <Button
                    type="default"
                    onClick={ () => dispatch( setModalType( null ) ) }
                    style={ { marginRight: "30px" } }
                >Cancel</Button>
                <Button type="primary" htmlType="submit">
                    Save
                </Button>
            </div>
        </Form>
    </Card>
}
