import React, { useEffect } from "react";
import styles from "./styles/contentPackage.module.scss"
import { Badge, Button, Card, Space } from "antd";
import { EditOutlined, SubnodeOutlined } from "@ant-design/icons";
import { v4 as uuidV4 } from "uuid"
import { useDispatch, useSelector } from "react-redux";
import { setModalTransferData, setModalType } from "../../Redux/Slices/modal";
import { getPackagesExemplars } from "../../Redux/Slices/ContentPackages/asyncThunk";
import Connection from "../../Services/connections";
import { HandlerParagraphTextLength } from "../Common/textHandlers";


const ContentPackages = () => {
    const dispatch = useDispatch()
    const contentPackages = useSelector( state => state.contentPackages.all )


    useEffect( () => {
        dispatch( getPackagesExemplars() )
    }, [ dispatch ] )

    return <div className={ styles.main }>
        <Button
            type="primary"
            onClick={ () => {
                dispatch( setModalType( "addContentPackage" ) )
            } }
            style={ { margin: "10px 0 20px 5px" } }
        >Add Content Package<SubnodeOutlined/></Button>
        <Space style={ { display: "flex", justifyContent: "center" } } size={ [ 8, 16 ] } wrap>
            {
                contentPackages.map( packageData => {
                    return <Card
                        key={ uuidV4() }
                        title={ packageData.name }
                        style={ {
                            userSelect: "none",
                            width: "370px",
                        } }
                        extra={ <Badge status={ packageData.in_live ? "success" : "error" } text="In live"/> }
                        size="large"
                        actions={ [
                            <>{ packageData.price } { packageData.pricePrefix }</>,
                            <>{ packageData.duration } { packageData.durationPrefix }</>,
                            <EditOutlined onClick={ () => {
                                dispatch( setModalTransferData( {
                                    modalType: "editContentPackage",
                                    transfer_id: packageData.id,
                                    process_type: "contentPackages",
                                } ) )
                            } }
                                          key="edit"/>,
                        ] }
                    >
                        <div className={ styles.lesson_image_container }>
                            <img
                                src={ Connection.GetImage( packageData.photo ) }
                                alt={ packageData.name }/>
                        </div>
                        <div className={ styles.lessons_icon }>
                            {
                                packageData.lessons.map( lesson => {
                                    return <img key={ uuidV4() } src={ Connection.GetImage( lesson.icon ) }
                                                alt={ lesson.name }/>
                                } )
                            }
                        </div>

                        <div style={{ height: "80px" }} className={ styles.info }>
                                <HandlerParagraphTextLength text={packageData.description} showedLength={150} />
                        </div>
                    </Card>
                } )
            }
        </Space>
    </div>
}

export default ContentPackages



