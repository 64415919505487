import { createSlice } from '@reduxjs/toolkit'
import { getCustomers } from "./asyncThunks";

export const index = createSlice( {
    name: 'customers',
    initialState: {
        all: [],
    },
    reducers: {},
    extraReducers: ( builder ) => {
        builder
            .addCase( getCustomers.fulfilled, ( state, action ) => {
                state.all = action.payload;
            } );
    }
} )

// export const { } = index.actions
export default index.reducer