import { v4 as uuidV4 } from "uuid"


export const weekDaysOption = [
    { key: uuidV4(), label: "Monday", value: "Monday" },
    { key: uuidV4(), label: "Tuesday", value: "Tuesday" },
    { key: uuidV4(), label: "Wednesday", value: "Wednesday" },
    { key: uuidV4(), label: "Thursday", value: "Thursday" },
    { key: uuidV4(), label: "Friday", value: "Friday" },
    { key: uuidV4(), label: "Saturday", value: "Saturday" },
    { key: uuidV4(), label: "Sunday", value: "Sunday" },
]

export const startEndOption = [
    { key: uuidV4(), label: "13:00 && 15:00", value: "13:00,15:00" },
    { key: uuidV4(), label: "15:00 && 17:00", value: "15:00,17:00" },
    { key: uuidV4(), label: "17:00 && 19:00", value: "17:00,19:00" },
    { key: uuidV4(), label: "19:00 && 21:00", value: "19:00,21:00" },
]

export const durationOption = [
    { key: uuidV4(), label: "25m", value: "25m" },
    { key: uuidV4(), label: "30m", value: "30m" },
    { key: uuidV4(), label: "45m", value: "45m" },
    { key: uuidV4(), label: "1h", value: "1h" },
    { key: uuidV4(), label: "2h", value: "2h" },
    { key: uuidV4(), label: "3h", value: "3h" },
    { key: uuidV4(), label: "4h", value: "4h" },
    { key: uuidV4(), label: "5h", value: "5h" },
]


export const classroomsOptions = [
    { label: "Classroom 1", value: "Classroom 1" },
    { label: "Classroom 2", value: "Classroom 2" },
]
