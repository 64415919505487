import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchingDataWithAxiosMiddleware } from "../../fetch";
import Connection from "../../../../Services/connections";
import { setLoadingState } from "../../loading";
import { notification } from "antd";

export const getExams = createAsyncThunk(
    'exams/getAll',
    async ( _, actions ) => {
        try {
            actions.dispatch( setLoadingState( true ) )
            const response = await fetchingDataWithAxiosMiddleware(
                "GET",
                Connection.GetAllExams(),
            );
            actions.dispatch( setLoadingState( false ) )
            return response.data.exams;
        } catch ( e ) {
            actions.dispatch( setLoadingState( false ) )
            notification.error( {
                placement: 'topRight',
                message: e.message,
            } );
        }
    }
);

export const addExams = createAsyncThunk(
    'exams/add',
    async ( data, actions ) => {
        try {
            actions.dispatch( setLoadingState( true ) )
            const formData = new FormData()
            for ( let prop in data ) {
                formData.append( prop, data[ prop ] || "" )
            }
            const response = await fetchingDataWithAxiosMiddleware(
                "POST",
                Connection.PostAddExam(),
                formData
            );
            if ( response.data.message === "success" ) {
                return actions.dispatch( getExams() )
            }
            return response.data.exams;
        } catch ( e ) {
            actions.dispatch( setLoadingState( false ) )
            notification.error( {
                placement: 'topRight',
                message: e.message,
            } );
        }
    }
);

export const addQuestion = createAsyncThunk(
    'exams/addQuestion',
    async ( data, actions ) => {
        try {
            actions.dispatch( setLoadingState( true ) )
            const formData = new FormData();

            for ( let prop in data ) {
                formData.append( prop, data[ prop ] || "" )
            }

            const response = await fetchingDataWithAxiosMiddleware(
                "POST",
                Connection.PostAddQuestion(),
                formData
            )

            if ( response.data.message === "success" ) {
                return actions.dispatch( getExams() )
            }
            return response.data.exams;
        } catch ( e ) {
            actions.dispatch( setLoadingState( false ) )
            notification.error( {
                placement: 'topRight',
                message: e.message,
            } );
        }
    }
);

export const editExams = createAsyncThunk(
    'exams/edit',
    async ( data, actions ) => {
        try {
            actions.dispatch( setLoadingState( true ) )
            const formData = new FormData()
            for ( let prop in data ) {
                if ( data[ prop ] ) {
                    formData.append( prop, data[ prop ] )
                }
            }
            formData.append( "process_type", actions.getState().modal.process_type )
            const response = await fetchingDataWithAxiosMiddleware(
                "PUT",
                Connection.PutEditExam(),
                formData
            );
            if ( response.data.message === "success" ) {
                return actions.dispatch( getExams() )
            }
            return response.data.exams;
        } catch ( e ) {
            actions.dispatch( setLoadingState( false ) )
            notification.error( {
                placement: 'topRight',
                message: e.message,
            } );
        }
    }
);

export const deleteExams = createAsyncThunk(
    'exams/delete',
    async ( data, actions ) => {
        try {
            actions.dispatch( setLoadingState( true ) )
            const response = await fetchingDataWithAxiosMiddleware(
                "DELETE",
                Connection.DeleteExam( data.id ),
            );
            if ( response.data.message === "success" ) {
                return actions.dispatch( getExams() )
            }
            return response.data.exams;
        } catch ( e ) {
            actions.dispatch( setLoadingState( false ) )
            notification.error( {
                placement: 'topRight',
                message: e.message,
            } );
        }
    }
);

export const deleteQuestion = createAsyncThunk(
    'exams/deleteQuestion',
    async ( data, actions ) => {
        try {
            actions.dispatch( setLoadingState( true ) )
            const response = await fetchingDataWithAxiosMiddleware(
                "DELETE",
                Connection.DeleteQuestion( data.id )
            )
            if ( response.data.message === "success" ) {
                return actions.dispatch( getExams() )
            }
            return response.data.exams;
        } catch ( e ) {
            actions.dispatch( setLoadingState( false ) )
            notification.error( {
                placement: 'topRight',
                message: e.message,
            } )
        }
    }
);