import React, { useEffect } from 'react';
import {
    DeleteOutlined,
    EditOutlined,
    UserAddOutlined,
} from '@ant-design/icons';
import {
    SortableContext,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Button, Table } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { setModalTransferData, setModalType } from "../../Redux/Slices/modal";
import { getTeachers } from "../../Redux/Slices/Education/Teachers/asyncThunks";
import { Row, rowSelection } from "../Common/tableUtils"


const expandableColumns = [
    {
        key: "5",
        title: 'Phone',
        dataIndex: 'phone'
    },
    {
        key: "6",
        title: 'Email',
        dataIndex: 'email'
    },
    {
        key: "7",
        title: 'Position',
        dataIndex: 'position',
    }
];

const Teachers = () => {
    const dispatch = useDispatch()
    const teachers = useSelector( state => state.teachers.all )

    useEffect(() => {
        if(!teachers.length){
            dispatch(getTeachers())
        }
    }, [dispatch])

    const columns = [
        {
            key: "1",
            title: 'ID',
            dataIndex: "id"
        },
        {
            key: "2",
            title: 'Teacher',
            dataIndex: 'name'
        },
        {
            key: "3",
            title: 'Last-name',
            dataIndex: "lastName"
        },
        {
            key: "4",
            title: 'Action',
            render: ( e ) => {
                return <>
                    <EditOutlined
                        onClick={ () => {
                            dispatch( setModalTransferData( {
                                modalType: "editTeacher",
                                transfer_id: e.id,
                                process_type: "teacher",
                            } ) )
                        } } style={ { cursor: "pointer", marginRight: "20px" } }
                    />
                    <DeleteOutlined
                        style={ { cursor: "pointer", color: "red" } }
                        onClick={ () => {
                            dispatch( setModalTransferData( {
                                modalType: "delete",
                                transfer_id: e.id,
                                process_type: "teacher",
                            } ) )
                        } }
                    />
                </>
            }
        },
    ];


    return <div style={ { width: "100%", minHeight: "100vh" } }>
        <Button
            type="primary"
            onClick={ () => {
                dispatch( setModalType( "addTeacher" ) )
            } }
            style={ { margin: "10px 0 20px 5px" } }
        >Add teacher<UserAddOutlined/></Button>
        <SortableContext
            items={ teachers.map( ( i ) => i.id ) }
            strategy={ verticalListSortingStrategy }
        >
            <Table
                components={ {
                    body: {
                        row: Row,
                    },
                } }
                expandable={ {
                    expandedRowRender: ( record ) => {
                        return <Table
                            rowKey="id"
                            columns={ expandableColumns }
                            dataSource={ [ record ] }
                            pagination={ false }
                        />;
                    },
                } }
                rowSelection={ {
                    type: "checkbox",
                    ...rowSelection,
                } }
                rowKey="id"
                columns={ columns }
                dataSource={ teachers }
            />
        </SortableContext>
    </div>
};
export default Teachers;