import { configureStore } from '@reduxjs/toolkit'
import { authentication } from "./Slices/auth";
import languages from "./Slices/languages";
import qr from "./Slices/Education/QR/qr";
import students from "./Slices/Education/Students";
import main from "./Slices/main";
import modal from "./Slices/modal";
import loading from "./Slices/loading";
import content from "./Slices/Content/content";
import contentPackages from "./Slices/ContentPackages/contentPackages";
import teachers from "./Slices/Education/Teachers/teachers";
import exams from "./Slices/Education/Exams/exams";
import groups from "./Slices/Education/Group/group";
import packages from "./Slices/Education/Packages/packages";
import lessons from "./Slices/Education/Lessons/lessons";
import timeLines from "./Slices/Education/TimeLine/timeLine";
import customers from "./Slices/Customer";


const store = configureStore( {
    reducer: {
        qr,
        main,
        modal,
        loading,
        exams,
        groups,
        content,
        lessons,
        teachers,
        packages,
        students,
        timeLines,
        languages,
        contentPackages,
        customers,
        authentication
    }
} )

export default store