import armFlag from "../../Assets/Languages/armenia-flag.svg"
import rusFlag from "../../Assets/Languages/russia-flag.svg"
import usFlag from "../../Assets/Languages/united-states-flag.svg"
import styles from "./styles/langages.module.scss"
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Connections from "../../Services/connections"
import { getLanguages, selectLanguage } from "../../Redux/Slices/languages";


const LanguagesHeader = () => {
    const dispatch = useDispatch()
    const languages = useSelector( state => state.languages.data )
    const selected = useSelector( state => state.languages.selected )

    useEffect( () => {
        dispatch( getLanguages() )
    }, [ dispatch ] )


    return <div className={ styles.main }>
        {
            languages.map( language => {
                return <div
                    key={ language.id }
                    onClick={ () => {
                        dispatch( selectLanguage( language.name ) )
                    } }
                    className={
                        selected === language.id
                            ? `${ styles.flag } ${ styles.active }`
                            : styles.flag
                    }
                >
                    <img src={ Connections.LanguageIcon( language.icon ) } alt={ language.name }/>
                </div>
            } )
        }
    </div>
}


export default LanguagesHeader