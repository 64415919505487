import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Card, Checkbox, Select, InputNumber } from 'antd';
import { setModalType } from "../../../Redux/Slices/modal";
import { getGroups } from "../../../Redux/Slices/Education/Group/asyncThunk";
import { editStudents } from "../../../Redux/Slices/Education/Students/asyncThunks";
import { editCustomer } from "../../../Redux/Slices/Customer/asyncThunks";

const MyFormItemContext = React.createContext( [] );

function toArr( str ) {
    return Array.isArray( str ) ? str : [ str ];
}

// const MyFormItemGroup = ( { prefix, children } ) => {
//     const prefixPath = React.useContext( MyFormItemContext );
//     const concatPath = React.useMemo( () => [ ...prefixPath, ...toArr( prefix ) ], [ prefixPath, prefix ] );
//     return <MyFormItemContext.Provider value={ concatPath }>{ children }</MyFormItemContext.Provider>;
// };
const MyFormItem = ( { name, ...props } ) => {
    const prefixPath = React.useContext( MyFormItemContext );
    const concatName = name !== undefined ? [ ...prefixPath, ...toArr( name ) ] : undefined;

    const getRules = ( name = "" ) => {
        if ( name === "email" ) {
            return [
                {
                    required: true,
                    type: "email",
                    message: "The input is not valid E-mail!",
                },
            ]
        }
        if ( name === "phone" ) {
            return [
                {
                    required: true,
                    type: "number",
                    message: "This field cannot be left blank",
                },
            ]
        }

        return [ { required: true, message: `This field cannot be left blank` } ]
    }


    return <Form.Item uploadedimage={ getRules( concatName[ 0 ] ) } name={ concatName } { ...props } />;
};


export const EditCustomerData = () => {
    const dispatch = useDispatch()
    const customers = useSelector( state => state.customers.all )
    const transfer_id = useSelector( state => state.modal.transfer_id )
    const selected_customer = customers?.filter( customer => customer.id === transfer_id )[ 0 ]
    const [ status, setStatus ] = useState( selected_customer.status || "waiting" )
    const customerStatusOption = [
        {
            key: "waiting",
            label: "Waiting",
            value: "waiting"
        },
        {
            key: "connected",
            label: "Connected",
            value: "connected"
        },
        {
            key: "finished",
            label: "Finished",
            value: "finished"
        },
    ]


    // useEffect( () => {
    //     if ( !groups.length ) {
    //         dispatch( getGroups() )
    //     }
    // }, [ dispatch, groups.length ] )


    const handleGroupChange = ( data ) => {
        setStatus( data.value )
    }

    const onFinish = ( value ) => {
        let data = {}
        if ( status !== selected_customer.status ) {
            data.status = status
        }
        for ( let prop in value ) {
            if ( value[ prop ]?.toString() !== selected_customer[ prop ].toString() ) {
                data[ prop ] = value[ prop ]
            }
        }
        if ( Object.keys( data ).length !== 0 ) {
            data.id = transfer_id
            dispatch( editCustomer( data ) )
            dispatch( setModalType( null ) )
        }
    };

    return <Card title={ `Edit Customer ID: ${ transfer_id }` }
                 style={ {
                     width: 370,
                     // display: "flex",
                     textAlign: "center",
                     // flexDirection: "column"
                 } }>
        <Form
            initialValues={ {
                "name": selected_customer.name,
                "lastName": selected_customer.lastName,
                "email": selected_customer.email,
                "phone": parseInt( selected_customer.phone ),
            } }
            name="form_item_path" layout="vertical" onFinish={ onFinish }
        >
            <MyFormItem name="name" label="First Name">
                <Input disabled={ selected_customer.status === "finished" }/>
            </MyFormItem>
            <MyFormItem name="lastName" label="Last Name">
                <Input disabled={ selected_customer.status === "finished" }/>
            </MyFormItem>
            <MyFormItem name="email" label="Email">
                <Input disabled={ selected_customer.status === "finished" }/>
            </MyFormItem>
            <MyFormItem name="phone" label="Phone">
                <InputNumber
                    disabled={ selected_customer.status === "finished" }
                    minLength={ 8 }
                    maxLength={ 9 }
                    style={ { width: "100%" } }
                />
            </MyFormItem>

            <Select
                labelInValue
                allowClear
                disabled={ selected_customer.status === "finished" }
                style={ {
                    position: "relative",
                    margin: "15px 0",
                    width: '100%',
                } }
                placeholder={
                    selected_customer.status.charAt( 0 ).toUpperCase() + selected_customer.status.slice( 1 )
                }
                onChange={ handleGroupChange }
                options={ customerStatusOption }
            />

            <div style={ { width: "100%", display: "flex", justifyContent: "center" } }>
                <Button
                    type="default"
                    onClick={ () => dispatch( setModalType( null ) ) }
                    style={ { marginRight: "30px" } }
                >Cancel</Button>
                <Button
                    disabled={ selected_customer.status === "finished" }
                    type="primary" htmlType="submit"
                >
                    Save
                </Button>
            </div>
        </Form>
    </Card>
}
