import { createSlice } from '@reduxjs/toolkit'
import js_SVG from "../../../../Assets/Exams/js.svg"
import { getExams } from "./asyncThunk";


export const exams = createSlice( {
    name: 'exams',
    initialState: {
        all: [
            {
                id: 1,
                duration: 30,
                icon: js_SVG,
                level: "Hard",
                name: "Logical",
                duration_format: "minutes",
                questions: [
                    {
                        id: 1,
                        title: "Logical Deduction",
                        question: "If all cats are mammals and Fluffy is a cat, what can be logically concluded about Fluffy?",
                        answers: [
                            "A) Fluffy is a mammal.",
                            "B) Fluffy is a reptile.",
                            "C) Fluffy is a bird.",
                            "D) Fluffy is an amphibian."
                        ],
                    },
                    {
                        id: 2,
                        title: "Pattern Recognition",
                        question: "Identify the next item in the series: Square, Circle, Triangle, __?",
                        answers: [
                            "A) Pentagon",
                            "B) Hexagon",
                            "C) Rectangle",
                            "D) Oval"
                        ],
                    },
                    {
                        id: 3,
                        title: "Word Relationships",
                        question: "Choose the word that does not belong: Carrot, Apple, Orange, Chair.",
                        answers: [
                            "A) Carrot",
                            "B) Apple",
                            "C) Orange",
                            "D) Chair"
                        ],
                    },
                    {
                        id: 4,
                        title: "Analogies",
                        question: "Complete the analogy: Sun is to Day as Moon is to __?",
                        answers: [
                            "A) Dark",
                            "B) Night",
                            "C) Star",
                            "D) Eclipse"
                        ],
                    }
                ]
            },
        ]
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getExams.fulfilled, (state, action) => {
            state.all = action.payload
        })
    }
} )

export const {} = exams.actions
export default exams.reducer