import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Card, Select } from 'antd';
import { setModalType } from "../../../Redux/Slices/modal";
import { v4 as uuidV4 } from "uuid"
import { getTimeLines } from "../../../Redux/Slices/Education/TimeLine/asyncThunks";
import { getLessons } from "../../../Redux/Slices/Education/Lessons/asyncThunks";
import { getTeachers } from "../../../Redux/Slices/Education/Teachers/asyncThunks";
import { editPackage } from "../../../Redux/Slices/Education/Packages/asyncThunks";

const MyFormItemContext = React.createContext( [] );

function toArr( str ) {
    return Array.isArray( str ) ? str : [ str ];
}

const MyFormItem = ( { name, ...props } ) => {
    const prefixPath = React.useContext( MyFormItemContext );
    const concatName = name !== undefined ? [ ...prefixPath, ...toArr( name ) ] : undefined;
    return <Form.Item name={ concatName } { ...props } />;
};


export const EditPackage = () => {
    const dispatch = useDispatch()
    const times = useSelector( state => state.timeLines.all )
    const transfer_id = useSelector( state => state.modal.transfer_id )
    const packages = useSelector( state => state.packages.all )
    const lessons = useSelector( state => state.lessons.all )
    const teachers = useSelector( state => state.teachers.all )
    const selectedPackage = packages.filter( item => item.id === transfer_id )[ 0 ]
    const [ addedLessons, setLesson ] = useState( null )
    const [ timeId, setTimeId ] = useState( null )
    const [ selectedData, selectData ] = useState()


    const timeOptions = times?.filter( time => time?.packages.length === 0 )?.map( time => {
        return {
            key: uuidV4(),
            label: `${ time?.weekDays } ${ time?.dayStartTime }`,
            value: `${ time?.id }`,
        }
    } )

    const lessonsOptions = lessons?.map( lesson => {
        return {
            key: uuidV4(),
            label: lesson.name,
            value: lesson.id,
        }
    } )

    useEffect( () => {
        dispatch( getTimeLines() )
        dispatch( getLessons() )
        dispatch( getTeachers() )
    }, [ dispatch ] )


    const onFinish = ( value ) => {
        let data = {
            id: transfer_id,
            TimeLineId: timeId
        }
        if ( addedLessons ) {
            data.lessons = addedLessons
        }
        if ( selectedData?.time?.teacherId ) {
            data.teacherId = selectedData?.time?.teacherId
        }
        for ( let prop in value ) {
            if ( value[ prop ] && value[ prop ] !== selectedPackage.name ) {
                data[ prop ] = value[ prop ]
            }
        }
        dispatch( editPackage( data ) )
        dispatch( setModalType( null ) )
    };

    const handleChangeStartEndTime = ( data ) => {
        setTimeId( parseInt( data?.value ) )
        selectData( {
            time: times.filter( time => time.id === parseInt( data?.value ) )[ 0 ]
        } )
    }

    const handleChangeLessons = ( data ) => {
        setLesson( data )
    }


    const payload = selectedData?.time ? selectedData.time : selectedPackage?.TimeLine


    return <Card
        style={ { position: "relative", width: 370, textAlign: "center", display: "flex", flexDirection: "column" } }
        title={ `Edit Package ID: ${ selectedPackage?.id || "" }` }
    >
        <Form
            name="form_item_path"
            layout="vertical"
            onFinish={ onFinish }
            initialValues={ {
                "name": selectedPackage?.name
            } }
        >

            <MyFormItem name="name" label="Package">
                <Input placeholder="Package name"/>
            </MyFormItem>

            <Select
                labelInValue
                allowClear
                style={ {
                    position: "relative",
                    margin: "25px 0",
                    width: '100%',
                } }
                placeholder={
                    `${ payload?.weekDays
                    } ${ payload?.dayStartTime
                    }`
                }
                onChange={ handleChangeStartEndTime }
                options={ timeOptions }
            />


            <MyFormItem name="duration" label="Duration">
                <Input placeholder={ payload?.duration } disabled/>
            </MyFormItem>

            <MyFormItem name="classroom" label="Classroom">
                <Input placeholder={ payload?.classroom } disabled/>
            </MyFormItem>

            <Select
                mode="tags"
                style={ {
                    position: "relative",
                    width: '100%',
                    margin: "10px 0 20px 0"
                } }
                placeholder="Lessons"
                defaultValue={
                    selectedPackage?.lessons.map( lesson => {
                        return {
                            key: uuidV4(),
                            label: lesson.name,
                            value: lesson.id
                        }
                    } )
                }
                onChange={ handleChangeLessons }
                options={ lessonsOptions }
            />

            <MyFormItem name="teacherId" label="Teacher">
                <Input placeholder={
                    `${ teachers.filter( teacher => teacher.id === payload?.teacherId )[ 0 ]?.name
                    } ${ teachers.filter( teacher => teacher.id === payload?.teacherId )[ 0 ]?.lastName
                    } || ${ teachers.filter( teacher => teacher.id === payload?.teacherId )[ 0 ]?.position
                    }`
                } disabled/>
            </MyFormItem>

            <div style={ { width: "100%", display: "flex", justifyContent: "center" } }>
                <Button
                    type="default"
                    onClick={ () => dispatch( setModalType( null ) ) }
                    style={ { marginRight: "30px" } }
                >Cancel</Button>
                <Button type="primary" htmlType="submit">
                    Edit
                </Button>
            </div>
        </Form>
    </Card>
}
