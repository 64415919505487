class Service {

    saveToken( token ) {
        localStorage.setItem( "token", JSON.stringify( token ) )
    }

    saveSelectedMenuItem( selected ) {
        sessionStorage.setItem( "menu_item", JSON.stringify( selected ) )
    }

    getToken() {
        return JSON.parse( String( localStorage.getItem( "token" ) ) )
    }

    getSelectedMenuItem() {
        return JSON.parse( String( sessionStorage.getItem( "menu_item" ) ) )
    }

    removeToken() {
        localStorage.removeItem( "token" )
    }
}


export default new Service()