import React, { useEffect } from 'react';
import {
    DeleteOutlined,
    EditOutlined, FileAddOutlined, FileExcelOutlined, FileSyncOutlined,
    ProfileOutlined,
} from '@ant-design/icons';
import {
    SortableContext,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Button, Table } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { setModalTransferData, setModalType } from "../../Redux/Slices/modal";
import LanguagesHeader from "../Common/languages";
import { getLessons } from "../../Redux/Slices/Education/Lessons/asyncThunks";
import { Row, rowSelection } from "../Common/tableUtils"
import Connection from "../../Services/connections";


const Lessons = () => {
    const dispatch = useDispatch()
    const selected = useSelector( state => state.languages.selected )
    const lessons = useSelector( state => state.lessons.all )


    useEffect( () => {
        dispatch( getLessons() )
    }, [ dispatch ] )

    const expandableColumns = [
        {
            key: "6",
            title: 'ID',
            dataIndex: "id",
        },
        {
            key: "7",
            title: 'Title',
            dataIndex: "title",
            render: ( data ) => {
                return <p>{ data[ selected ] || "empty" }</p>
            }
        },
        {
            key: "8",
            title: 'Description',
            dataIndex: "description",
            render: ( data ) => {
                return <p>{ data[ selected ] || "empty" }</p>
            }
        },
        {
            key: "9",
            title: 'Actions',
            render: ( record ) => {
                return <>
                    <FileSyncOutlined
                        style={ { fontSize: "20px", cursor: "pointer" } }
                        onClick={ () => {
                            dispatch( setModalTransferData( {
                                modalType: "editSubLesson",
                                transfer_id: record.lessonId,
                                process_type: `${ record.id }`,
                            } ) )
                        } }
                    />
                    <FileExcelOutlined
                        style={ { margin: "10px 0 0 10px", fontSize: "20px", cursor: "pointer", color: "red" } }
                        onClick={ () => {
                            dispatch( setModalTransferData( {
                                modalType: "delete",
                                transfer_id: record.id,
                                process_type: "subLessons",
                            } ) )
                        } }
                    />
                </>
            }
        },
    ];


    const columns = [
        {
            key: "1",
            title: 'ID',
            dataIndex: "id"
        },
        {
            key: "2",
            title: 'Name',
            dataIndex: "name"
        },
        {
            key: "3",
            title: 'Icon',
            render: ( data ) => {
                return <img style={ { width: "50px", height: "50px", objectFit: "cover" } }
                            src={ Connection.GetImage( data.icon ) }
                            alt={ data.name }/>
            }
        },
        {
            key: "4",
            title: 'Title',
            render: ( data ) => {
                return <p>{ data?.title[ selected ] || "Empty" }</p>
            }
        },
        {
            key: "5",
            title: 'Description',
            render: ( data ) => {
                return <p
                    style={ { width: "350px", textAlign: "justify" } }>{ data?.description[ selected ] || "Empty" }</p>
            }
        },
        {
            key: "6",
            title: 'Duration',
            render: ( data ) => {
                return <p
                    style={ { textAlign: "justify" } }>{ `${ data?.duration } ${ data.durationPrefix }` || "Empty" }</p>
            }
        },
        {
            key: "7",
            title: 'Price',
            render: ( data ) => {
                return <p style={ { textAlign: "justify" } }>{ data?.price[ selected ] || "Empty" }</p>
            }
        },
        {
            key: "8",
            title: 'Action',
            render: ( e ) => {
                return <>
                    <EditOutlined
                        onClick={ () => {
                            dispatch( setModalTransferData( {
                                modalType: "editLesson",
                                transfer_id: e.id,
                                process_type: "lessons",
                            } ) )
                        } } style={ { cursor: "pointer", marginRight: "20px" } }
                    />
                    <DeleteOutlined
                        style={ { cursor: "pointer", color: "red" } }
                        onClick={ () => {
                            dispatch( setModalTransferData( {
                                modalType: "delete",
                                transfer_id: e.id,
                                process_type: "lessons",
                            } ) )
                        } }
                    />
                </>
            }
        },
    ];


    return <div style={ { width: "100%", minHeight: "100vh" } }>
        <Button type="primary"
                onClick={ () => {
                    dispatch( setModalType( "addLesson" ) )
                } }
        >Add Lesson <ProfileOutlined/> </Button>
        <LanguagesHeader/>
        <SortableContext
            items={ lessons.map( ( i ) => i.id ) }
            strategy={ verticalListSortingStrategy }
        >
            <Table
                components={ {
                    body: {
                        row: Row,
                    },
                } }
                expandable={ {
                    expandedRowRender: ( record ) => {
                        return <>
                            <Table
                                rowKey="id"
                                columns={ expandableColumns }
                                dataSource={ record.sub_lessons || [] }
                                pagination={ false }
                            />
                            <div style={ {
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                margin: "15px 0"
                            } }>
                                <FileAddOutlined
                                    onClick={ () => {
                                        dispatch( setModalTransferData( {
                                            modalType: "addSubLesson",
                                            transfer_id: record.id,
                                            process_type: "addSubLesson",
                                        } ) )
                                    } }
                                    style={ { cursor: "pointer", fontSize: "30px" } }
                                />
                            </div>
                        </>
                    },
                } }
                rowSelection={ {
                    type: "checkbox",
                    ...rowSelection,
                } }
                rowKey="id"
                columns={ columns }
                dataSource={ lessons }
            />
        </SortableContext>
    </div>
};
export default Lessons;