// import { useParams } from "react-router";


const VerifyQR = () => {
    // const params = useParams()
    // const token = params?.token

    return <div style={{width: "100%", height: "100vh", background: "beige", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "30px"}}>
        GENIE WEB
    </div>
}


export default VerifyQR