import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchingDataWithAxiosMiddleware } from "../../fetch";
import Connection from "../../../../Services/connections";
import { setLoadingState } from "../../loading";
import { notification } from "antd";

export const getPackages = createAsyncThunk(
    'packages/getAll',
    async ( _, actions ) => {
        try {
            actions.dispatch( setLoadingState( true ) )
            const response = await fetchingDataWithAxiosMiddleware(
                "GET",
                Connection.EducationPackages()
            );
            actions.dispatch( setLoadingState( false ) )
            return response.data.packages;
        } catch ( e ) {
            actions.dispatch( setLoadingState( false ) )
            notification.error( {
                placement: 'topRight',
                message: e.message,
            } );
        }
    }
);


export const addPackage = createAsyncThunk(
    'packages/add',
    async ( data, actions ) => {
        actions.dispatch( setLoadingState( true ) )
        try {
            const formData = new FormData()
            for ( let prop in data ) {
                formData.append( prop, data[ prop ] )
            }

            const response = await fetchingDataWithAxiosMiddleware(
                "POST",
                Connection.EducationPackages(),
                formData
            );

            if ( response.data.message === "success" ) {
                notification.success( {
                    placement: 'topRight',
                    message: "Successfully added",
                } );
                return actions.dispatch( getPackages() )
            }
        } catch ( e ) {
            actions.dispatch( setLoadingState( false ) )
            notification.error( {
                placement: 'topRight',
                message: e.message,
            } );
        }
    }
);


export const editPackage = createAsyncThunk(
    'packages/edit',
    async ( data, actions ) => {
        actions.dispatch( setLoadingState( true ) )
        try {
            const formData = new FormData()
            for ( let prop in data ) {
                if ( data[ prop ] ) {
                    formData.append( prop, data[ prop ] )
                }
            }

            const response = await fetchingDataWithAxiosMiddleware(
                "PUT",
                Connection.EducationPackages(),
                formData
            );

            if ( response.data.message === "success" ) {
                notification.success( {
                    placement: 'topRight',
                    message: "Successfully edit",
                } );
                return actions.dispatch( getPackages() )
            }
        } catch ( e ) {
            actions.dispatch( setLoadingState( false ) )
            notification.error( {
                placement: 'topRight',
                message: e.message,
            } );
        }
    }
);


export const deletePackage = createAsyncThunk(
    'packages/delete',
    async ( data, actions ) => {
        actions.dispatch( setLoadingState( true ) )
        try {
            const response = await fetchingDataWithAxiosMiddleware(
                "DELETE",
                Connection.EducationPackages( data.id ),
            );

            if ( response.data.message === "success" ) {
                notification.success( {
                    placement: 'topRight',
                    message: "Successfully deleted",
                } );
                return actions.dispatch( getPackages() )
            }
        } catch ( e ) {
            actions.dispatch( setLoadingState( false ) )
            notification.error( {
                placement: 'topRight',
                message: e.message,
            } );
        }
    }
);
