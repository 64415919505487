import React, { useEffect, useState } from 'react';
import {
    DeploymentUnitOutlined,
    FieldTimeOutlined, GlobalOutlined, HeatMapOutlined, Html5Outlined,
    LogoutOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined, OrderedListOutlined,
    ProductOutlined,
    ProfileOutlined,
    QrcodeOutlined,
    RadarChartOutlined,
    ReadOutlined, ReconciliationOutlined,
    SolutionOutlined,
    TeamOutlined,
    UserOutlined, UserSwitchOutlined,
} from '@ant-design/icons';
import { Layout, Menu, Button, theme } from 'antd';
import { checkAccount } from "../../Redux/Slices/auth";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import Students from "../Students";
import ContentControl from "../Content";
import Qr from "../QR";
import { setModalType } from "../../Redux/Slices/modal";
import dataControl from "../../Services/dataControl";
import Exams from "../Exam";
import Teachers from "../Teachers";
import Groups from "../Groups";
import Packages from "../Packages";
import Lessons from "../Lessons";
import TimeLines from '../TimeLine';
import Loading from "../Loading";
import ContentPackages from "../ContentPackages";
import Customers from "../Customer";

const { Header, Sider, Content } = Layout;


const Main = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [ collapsed, setCollapsed ] = useState( false );
    const [ selectedMenuItem, selectItemOfMenu ] = useState( dataControl.getSelectedMenuItem()?.name || "Customers" );


    //TODO check platform in student without group mode

    useEffect( () => {
        dispatch( checkAccount( navigate ) )
    }, [] )

    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    return (
        <Layout
            style={ { width: "100%", minHeight: "100vh" } }
        >
            <Sider trigger={ null } collapsible collapsed={ collapsed }>
                <div style={ { marginBottom: "50px" } } className="demo-logo-vertical"/>
                <Menu
                    theme="dark"
                    mode="inline"
                    defaultSelectedKeys={ [
                        dataControl.getSelectedMenuItem()?.key ?
                            `${ dataControl.getSelectedMenuItem().key }` : '1'
                    ] }
                    items={ [
                        {
                            key: '1',
                            icon: <UserSwitchOutlined/>,
                            label: 'Customers',
                            onClick: () => {
                                selectItemOfMenu( () => {
                                    const name = "Customers"
                                    dataControl.saveSelectedMenuItem( { name, key: 1 } )
                                    return name
                                } )
                            }
                        },
                        {
                            key: '2',
                            icon: <ProfileOutlined/>,
                            label: 'Content',
                            children: [
                                {
                                    key: '21',
                                    icon: <GlobalOutlined />,
                                    label: 'Main page',
                                    onClick: () => {
                                        selectItemOfMenu( () => {
                                            const name = "page: main"
                                            dataControl.saveSelectedMenuItem( { name, key: 21 } )
                                            return name
                                        } )
                                    }
                                },
                                {
                                    key: '22',
                                    icon: <HeatMapOutlined />,
                                    label: 'Company page',
                                    onClick: () => {
                                        selectItemOfMenu( () => {
                                            const name = "page: company"
                                            dataControl.saveSelectedMenuItem( { name, key: 22 } )
                                            return name
                                        } )
                                    }
                                },
                                {
                                    key: '23',
                                    icon: <RadarChartOutlined />,
                                    label: 'Services page',
                                    onClick: () => {
                                        selectItemOfMenu( () => {
                                            const name = "page: services"
                                            dataControl.saveSelectedMenuItem( { name, key: 23 } )
                                            return name
                                        } )
                                    }
                                },
                                {
                                    key: '24',
                                    icon: <Html5Outlined />,
                                    label: 'Courses page',
                                    onClick: () => {
                                        selectItemOfMenu( () => {
                                            const name = "page: courses"
                                            dataControl.saveSelectedMenuItem( { name, key: 24 } )
                                            return name
                                        } )
                                    }
                                },
                            ]
                        },
                        {
                            key: '3',
                            icon: <OrderedListOutlined/>,
                            label: 'Content Packages',
                            onClick: () => {
                                selectItemOfMenu( () => {
                                    const name = "Content Packages"
                                    dataControl.saveSelectedMenuItem( { name, key: 3 } )
                                    return name
                                } )
                            }
                        },
                        {
                            key: '4',
                            icon: <ReconciliationOutlined/>,
                            label: 'Education',
                            children: [
                                {
                                    key: '11',
                                    icon: <QrcodeOutlined/>,
                                    label: 'QRS',
                                    onClick: () => {
                                        selectItemOfMenu( () => {
                                            const name = "qrs"
                                            dataControl.saveSelectedMenuItem( { name, key: 11 } )
                                            return name
                                        } )
                                    }
                                },
                                {
                                    key: '12',
                                    icon: <UserOutlined/>,
                                    label: 'Students',
                                    onClick: () => {
                                        selectItemOfMenu( () => {
                                            const name = "students"
                                            dataControl.saveSelectedMenuItem( { name, key: 12 } )
                                            return name
                                        } )
                                    }
                                },
                                {
                                    key: '13',
                                    icon: <TeamOutlined/>,
                                    label: 'Teachers',
                                    onClick: () => {
                                        selectItemOfMenu( () => {
                                            const name = "teachers"
                                            dataControl.saveSelectedMenuItem( { name, key: 13 } )
                                            return name
                                        } )
                                    }
                                },
                                {
                                    key: '14',
                                    icon: <FieldTimeOutlined/>,
                                    label: 'Time Line',
                                    onClick: () => {
                                        selectItemOfMenu( () => {
                                            const name = "Times"
                                            dataControl.saveSelectedMenuItem( { name, key: 14 } )
                                            return name
                                        } )
                                    }
                                },
                                {
                                    key: '15',
                                    icon: <ReadOutlined/>,
                                    label: 'Exams',
                                    onClick: () => {
                                        selectItemOfMenu( () => {
                                            const name = "exams"
                                            dataControl.saveSelectedMenuItem( { name, key: 15 } )
                                            return name
                                        } )
                                    }
                                },
                                {
                                    key: '16',
                                    icon: <DeploymentUnitOutlined/>,
                                    label: 'Groups',
                                    onClick: () => {
                                        selectItemOfMenu( () => {
                                            const name = "groups"
                                            dataControl.saveSelectedMenuItem( { name, key: 16 } )
                                            return name
                                        } )
                                    }
                                },
                                {
                                    key: '17',
                                    icon: <ProductOutlined/>,
                                    label: 'Packages',
                                    onClick: () => {
                                        selectItemOfMenu( () => {
                                            const name = "packages"
                                            dataControl.saveSelectedMenuItem( { name, key: 17 } )
                                            return name
                                        } )
                                    }
                                },
                                {
                                    key: '18',
                                    icon: <SolutionOutlined/>,
                                    label: 'Lessons',
                                    onClick: () => {
                                        selectItemOfMenu( () => {
                                            const name = "lessons"
                                            dataControl.saveSelectedMenuItem( { name, key: 18 } )
                                            return name
                                        } )
                                    }
                                }
                            ]
                        },
                        {
                            key: '5',
                            icon: <LogoutOutlined/>,
                            label: 'Logout',
                            onClick: () => {
                                dispatch( setModalType( "logout" ) )
                            }
                        },
                    ] }
                />
            </Sider>
            <Layout>
                <Header
                    style={ {
                        padding: 0,
                        background: colorBgContainer,
                        display: "flex",
                        justifyContent: "space-between"
                    } }
                >
                    <Button
                        type="text"
                        icon={ collapsed ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/> }
                        onClick={ () => setCollapsed( !collapsed ) }
                        style={ {
                            fontSize: '16px',
                            width: 64,
                            height: 64,
                        } }
                    />
                    <h1>{ selectedMenuItem.toUpperCase() }</h1>
                    <div/>
                </Header>
                <Content
                    style={ {
                        margin: '24px 16px',
                        padding: 24,
                        minHeight: 280,
                        background: colorBgContainer,
                        borderRadius: borderRadiusLG,
                    } }
                >
                    {
                        selectedMenuItem === "students" ? <Students/>
                            : selectedMenuItem === "qrs" ? <Qr/>
                                : selectedMenuItem.indexOf( "page:" ) >= 0 ? <ContentControl
                                    filter={ selectedMenuItem.substring( 6 ) }
                                /> : selectedMenuItem === "teachers" ? <Teachers/>
                                    : selectedMenuItem === "exams" ? <Exams/>
                                        : selectedMenuItem === "groups" ? <Groups/>
                                            : selectedMenuItem === "packages" ? <Packages/>
                                                : selectedMenuItem === "lessons" ? <Lessons/>
                                                    : selectedMenuItem === "Times" ? <TimeLines/>
                                                        : selectedMenuItem === "Content Packages" ? <ContentPackages/>
                                                            : selectedMenuItem === "Customers" ? <Customers/>
                                                                : <></>
                    }
                </Content>
            </Layout>
            <Loading/>
        </Layout>
    );
};
export default Main;