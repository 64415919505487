import React, { useEffect } from 'react';
import {
    DeleteOutlined,
    EditOutlined,
    UserAddOutlined,
} from '@ant-design/icons';
import {
    SortableContext,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Button, Table } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { setModalTransferData, setModalType } from "../../Redux/Slices/modal";
import { Row, rowSelection } from "../Common/tableUtils"
import { getTimeLines } from '../../Redux/Slices/Education/TimeLine/asyncThunks';
import { v4 as uuidV4 } from "uuid";


const expandableColumns = [
    {
        key: "6",
        title: 'Classroom',
        dataIndex: 'classroom',
    },
    {
        key: "7",
        title: 'Package Name',
        render: ( record ) => {
            return record?.packages[ 0 ]?.name || <b><i>Empty</i></b>
        }
    }
];

const TimeLines = () => {
    const dispatch = useDispatch()
    const timeLines = useSelector( state => state.timeLines.all )

    useEffect( () => {
        if ( !timeLines.length ) {
            dispatch( getTimeLines() )
        }
    }, [ dispatch ] )

    const columns = [
        {
            key: "1",
            title: 'ID',
            dataIndex: "id"
        },
        {
            key: "2",
            title: 'Week Days',
            dataIndex: 'weekDays'
        },
        {
            key: "3",
            title: 'Duration',
            dataIndex: "duration"
        },
        {
            key: "4",
            title: 'Start & End',
            render: ( record ) => {
                return `${ record?.dayStartTime || "empty" } && ${ record?.dayFinishTime || "empty" }` ||
                    <b><i>Empty</i></b>
            }
        },
        {
            key: "5",
            title: 'Action',
            render: ( e ) => {
                return <>
                    <EditOutlined
                        key={ uuidV4() }
                        onClick={ () => {
                            dispatch( setModalTransferData( {
                                modalType: "editTimeLine",
                                transfer_id: e.id,
                                process_type: "timeLine",
                            } ) )
                        } } style={ { cursor: "pointer", marginRight: "20px" } }
                    />
                    <DeleteOutlined
                        key={ uuidV4() }
                        style={ { cursor: "pointer", color: "red" } }
                        onClick={ () => {
                            dispatch( setModalTransferData( {
                                modalType: "delete",
                                transfer_id: e.id,
                                process_type: "timeLine",
                            } ) )
                        } }
                    />
                </>
            }
        },
    ];


    return <div style={ { width: "100%", minHeight: "100vh" } }>
        <Button
            type="primary"
            onClick={ () => {
                dispatch( setModalType( "addTime" ) )
            } }
            style={ { margin: "10px 0 20px 5px" } }
        >Add Time<UserAddOutlined/></Button>
        <SortableContext
            items={ timeLines.map( ( i ) => i.id ) }
            strategy={ verticalListSortingStrategy }
        >
            <Table
                components={ {
                    body: {
                        row: Row,
                    },
                } }
                expandable={ {
                    expandedRowRender: ( record ) => {
                        return <Table
                            key={ uuidV4() }
                            columns={ expandableColumns }
                            dataSource={ [ record ] }
                            pagination={ false }
                        />;
                    },
                } }
                rowSelection={ {
                    type: "checkbox",
                    ...rowSelection,
                } }
                rowKey="id"
                columns={ columns }
                dataSource={ timeLines }
            />
        </SortableContext>
    </div>
};
export default TimeLines;