import React, { useEffect } from 'react';
import {
    DeleteOutlined,
    EditOutlined,
    ProfileOutlined,
} from '@ant-design/icons';
import {
    SortableContext,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Button, Table } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { setModalTransferData, setModalType } from "../../Redux/Slices/modal";
import LanguagesHeader from "../Common/languages";
import { getPackages } from "../../Redux/Slices/Education/Packages/asyncThunks";
import { Row, rowSelection } from "../Common/tableUtils"


const Packages = () => {
    const dispatch = useDispatch()
    const langId = useSelector( state => state.languages.selected )
    const packages = useSelector( state => state.packages.all )


    useEffect( () => {
        dispatch( getPackages() )
    }, [ dispatch ] )

    const expandableColumns = [
        {
            key: "6",
            title: 'ID',
            dataIndex: "id",
        },
        {
            key: "7",
            title: 'Name',
            dataIndex: "name",
        },
    ];

    const expandableSubLessonsColumns = [
        {
            key: "8",
            title: 'ID',
            dataIndex: "id",
        },
        {
            key: "9",
            title: 'Title',
            dataIndex: "title",
        },
        {
            key: "10",
            title: 'Description',
            dataIndex: "description",
        },
    ];

    const columns = [
        {
            key: "1",
            title: 'ID',
            dataIndex: "id"
        },
        {
            key: "2",
            title: 'Name',
            dataIndex: "name"
        },
        {
            key: "3",
            title: 'Week Days',
            render: ( record ) => {
                return record?.TimeLine?.weekDays || <b><i>Empty</i></b>
            }
        },
        {
            key: "4",
            title: 'Start & End',
            render: ( record ) => {
                return `${ record?.TimeLine?.dayStartTime || "empty" } && ${ record?.TimeLine?.dayFinishTime || "empty" }` ||
                    <b><i>Empty</i></b>
            }
        },
        {
            key: "5",
            title: 'Duration',
            render: ( record ) => {
                return record?.TimeLine?.duration || <b><i>Empty</i></b>
            }
        },
        {
            key: "6",
            title: 'Teacher',
            render: ( record ) => {
                return `${record?.teacher?.name} ${record?.teacher?.lastName}` || <b><i>Empty</i></b>
            }
        },
        {
            key: "7",
            title: 'Action',
            render: ( e ) => {
                return <>
                    <EditOutlined
                        onClick={ () => {
                            dispatch( setModalTransferData( {
                                modalType: "editPackage",
                                transfer_id: e.id,
                                process_type: "packages",
                            } ) )
                        } } style={ { cursor: "pointer", marginRight: "20px" } }
                    />
                    <DeleteOutlined
                        style={ { cursor: "pointer", color: "red" } }
                        onClick={ () => {
                            dispatch( setModalTransferData( {
                                modalType: "delete",
                                transfer_id: e.id,
                                process_type: "packages",
                            } ) )
                        } }
                    />
                </>
            }
        },
    ];


    return <div style={ { width: "100%", minHeight: "100vh" } }>
        <Button type="primary"
                onClick={ () => {
                    dispatch( setModalType( "addPackage" ) )
                } }
        >Add Package <ProfileOutlined/> </Button>
        {/*<LanguagesHeader/>*/}
        <SortableContext
            items={ packages.map( ( i ) => i.id ) }
            strategy={ verticalListSortingStrategy }
        >
            <Table
                components={ {
                    body: {
                        row: Row,
                    },
                } }
                expandable={ {
                    expandedRowRender: ( record ) => {
                        return <>
                            <Table
                                rowKey="id"
                                columns={ expandableColumns }
                                dataSource={ record.lessons || [] }
                                pagination={ false }
                                expandable={ {
                                    expandedRowRender: record => {
                                        return <Table
                                            rowKey="id"
                                            columns={ expandableSubLessonsColumns }
                                            dataSource={ record.sub_lessons || [] }
                                            pagination={ false }
                                        />
                                    }
                                } }
                            />
                        </>
                    },
                } }
                rowSelection={ {
                    type: "checkbox",
                    ...rowSelection,
                } }
                rowKey="id"
                columns={ columns }
                dataSource={ packages }
            />
        </SortableContext>
    </div>
};
export default Packages;