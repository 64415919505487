import React, { useEffect } from 'react';
import {
    EditOutlined, FileAddOutlined, FileExcelOutlined, FileSyncOutlined,
} from '@ant-design/icons';
import {
    SortableContext,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Badge, Table } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { setModalTransferData } from "../../Redux/Slices/modal";
import LanguagesHeader from "../Common/languages";
import { getContent } from "../../Redux/Slices/Content/asyncThunk";
import { Row, rowSelection } from "../Common/tableUtils"
import Connection from "../../Services/connections"
import { v4 as uuidV4 } from "uuid";

const Content = ( { filter } ) => {
    const dispatch = useDispatch()
    const selected = useSelector( state => state.languages.selected )
    const allContent = useSelector( state => state.content.all )


    console.log(filter)
    useEffect( () => {
        dispatch( getContent() )
    }, [ dispatch ] )


    const expandableDescriptionColumns = [
        {
            key: "1",
            title: 'Title',
            render: ( { title } ) => {
                if ( title && title[ selected ] ) {
                    return <p>{ title[ selected ] }</p>
                }
                return <p>-</p>
            }
        },
        {
            key: "2",
            title: 'Text',
            render: ( { text } ) => {
                if ( text && text[ selected ] ) {
                    return <p>{ text[ selected ] }</p>
                }
                return <p>-</p>
            }
        },
        {
            key: "2",
            title: 'In Live',
            render: ( { can_show } ) => {
                return <Badge status={ can_show ? "success" : "error" } text={ can_show ? "Yes" : "No" }/>
            }
        },
        {
            key: "9",
            title: 'Actions',
            render: ( data ) => {
                return <>
                    <FileSyncOutlined
                        key="fileSyncOutlined"
                        style={ { fontSize: "20px", cursor: "pointer" } }
                        onClick={ () => {
                            dispatch( setModalTransferData( {
                                modalType: "editContentDescription",
                                transfer_id: data.id,
                                process_type: data?.PageId,
                            } ) )
                        } }
                    />
                    <FileExcelOutlined
                        key="fileExcelOutlined"
                        style={ { margin: "10px 0 0 10px", fontSize: "20px", cursor: "pointer", color: "red" } }
                        onClick={ () => {
                            dispatch( setModalTransferData( {
                                modalType: "delete",
                                transfer_id: data.id,
                                process_type: `contentDescription-${ data?.PageId }`,
                            } ) )
                        } }
                    />
                </>
            }
        },
    ];


    const expandableMoreItemColumns = [
        {
            key: "1",
            title: 'Title',
            render: ( { title } ) => {
                if ( title && title[ selected ] ) {
                    return <p>{ title[ selected ] }</p>
                }
                return <p>-</p>
            }
        },
        {
            key: "2",
            title: 'Text',
            render: ( { text } ) => {
                if ( text && text[ selected ] ) {
                    return <p>{ text[ selected ] }</p>
                }
                return <p>-</p>
            }
        },
        {
            key: "3",
            title: 'In Live',
            render: ( { can_show } ) => {
                return <Badge status={ can_show ? "success" : "error" } text={ can_show ? "Yes" : "No" }/>
            }
        },
        {
            key: "4",
            title: 'Icon',
            render: ( { icon } ) => {
                return icon ? <img style={ { width: "20px", height: "20px" } } src={ Connection.GetImage( icon ) }
                                   alt={ uuidV4() }/> : <p>-</p>
            }
        },
        {
            key: "9",
            title: 'Actions',
            render: ( data ) => {
                return <>
                    <FileSyncOutlined
                        key="fileSyncOutlined"
                        style={ { fontSize: "20px", cursor: "pointer" } }
                        onClick={ () => {
                            dispatch( setModalTransferData( {
                                modalType: "editContentMoreItem",
                                transfer_id: data.id,
                                process_type: data?.PageId,
                            } ) )
                        } }
                    />
                    <FileExcelOutlined
                        key="fileExcelOutlined"
                        style={ { margin: "10px 0 0 10px", fontSize: "20px", cursor: "pointer", color: "red" } }
                        onClick={ () => {
                            dispatch( setModalTransferData( {
                                modalType: "delete",
                                transfer_id: data.id,
                                process_type: `contentMoreItem-${ data?.PageId }`,
                            } ) )
                        } }
                    />
                </>
            }
        },
    ];

    const columns = [
        {
            key: "1",
            title: 'ID',
            dataIndex: "id"
        },
        // {
        //     key: "2",
        //     title: 'Page Name',
        //     dataIndex: "page"
        // },
        {
            key: "3",
            title: 'Section Name',
            dataIndex: "section_name"
        },
        {
            key: "4",
            title: 'In Live',
            render: ( { can_show } ) => {
                return <Badge status={ can_show ? "success" : "error" } text={ can_show ? "Yes" : "No" }/>
            }
        },
        {
            key: "3",
            title: 'Title',
            render: ( data ) => {
                if ( data.title && data.title[ selected ] ) {
                    return <p>{ data?.title[ selected ] }</p>
                }
                return <p>-</p>
            }
        },
        {
            key: "4",
            title: 'Action',
            render: ( e ) => {
                return <>
                    <EditOutlined
                        key="editOutlined"
                        onClick={ () => {
                            dispatch( setModalTransferData( {
                                modalType: "editContent",
                                transfer_id: e.id,
                                process_type: "content",
                            } ) )
                        } }
                        style={ { cursor: "pointer", marginRight: "20px" } }
                    />
                    {/*<DeleteOutlined*/ }
                    {/*    style={ { cursor: "pointer", color: "red" } }*/ }
                    {/*    onClick={ () => {*/ }
                    {/*        dispatch( setModalTransferData( {*/ }
                    {/*            modalType: "delete",*/ }
                    {/*            transfer_id: e.id,*/ }
                    {/*            process_type: "content",*/ }
                    {/*        } ) )*/ }
                    {/*    } }*/ }
                    {/*/>*/ }
                </>
            }
        },
    ];


    return <div style={ { width: "100%", minHeight: "100vh" } }>
        {/*<Button type="primary"*/ }
        {/*        onClick={ () => {*/ }
        {/*            dispatch( setModalType( "addContent" ) )*/ }
        {/*        } }*/ }
        {/*>Add Content <ProfileOutlined/> </Button>*/ }
        <LanguagesHeader/>
        <SortableContext
            items={ allContent.filter( content => content.page === filter ).map( ( i ) => i.id ) }
            strategy={ verticalListSortingStrategy }
        >
            <Table
                components={ {
                    body: {
                        row: Row,
                    },
                } }
                expandable={ {
                    expandedRowRender: ( record ) => {
                        return <>
                            <h3
                                style={ {
                                    userSelect: "none",
                                    color: "#4DB671",
                                    margin: "10px 0",
                                    textAlign: "center"
                                } }
                            >
                                Descriptions
                            </h3>
                            <Table
                                rowKey="id"
                                columns={ expandableDescriptionColumns }
                                dataSource={ record.Descriptions }
                                pagination={ false }
                            />
                            <div style={ {
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                padding: "20px 0",
                                borderBottom: "2px solid #4DB671"
                            } }>
                                <FileAddOutlined
                                    onClick={ () => {
                                        dispatch( setModalTransferData( {
                                            modalType: "addContentDescription",
                                            transfer_id: record.id,
                                            process_type: "addContentDescription",
                                        } ) )
                                    } }
                                    style={ { cursor: "pointer", fontSize: "30px" } }
                                />
                            </div>
                            <h3
                                style={ {
                                    userSelect: "none",
                                    color: "#4DB671",
                                    margin: "10px 0",
                                    textAlign: "center"
                                } }
                            >
                                More Items
                            </h3>
                            <Table
                                rowKey="id"
                                columns={ expandableMoreItemColumns }
                                dataSource={ record.MoreItems }
                                pagination={ false }
                            />
                            <div style={ {
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                padding: "20px 0",
                                borderBottom: "2px solid #4DB671"
                            } }>
                                <FileAddOutlined
                                    onClick={ () => {
                                        dispatch( setModalTransferData( {
                                            modalType: "addContentMoreItem",
                                            transfer_id: record.id,
                                            process_type: "addContentMoreItem",
                                        } ) )
                                    } }
                                    style={ { cursor: "pointer", fontSize: "30px" } }
                                />
                            </div>
                        </>
                    },
                } }
                rowSelection={ {
                    type: "checkbox",
                    ...rowSelection,
                } }
                rowKey="id"
                columns={ columns }
                dataSource={ allContent.filter( content => content.page === filter ) }
            />
        </SortableContext>
    </div>
};
export default Content;