import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Card, Space, Select, Checkbox } from 'antd';
import { setModalType } from "../../../Redux/Slices/modal";
import { addContent, addDescriptionContent } from "../../../Redux/Slices/Content/asyncThunk";

const { TextArea } = Input
const MyFormItemContext = React.createContext( [] );

function toArr( str ) {
    return Array.isArray( str ) ? str : [ str ];
}

const MyFormItemGroup = ( { prefix, children } ) => {
    const prefixPath = React.useContext( MyFormItemContext );
    const concatPath = React.useMemo( () => [ ...prefixPath, ...toArr( prefix ) ], [ prefixPath, prefix ] );
    return <MyFormItemContext.Provider value={ concatPath }>{ children }</MyFormItemContext.Provider>;
};
const MyFormItem = ( { name, ...props } ) => {
    const prefixPath = React.useContext( MyFormItemContext );
    const concatName = name !== undefined ? [ ...prefixPath, ...toArr( name ) ] : undefined;

    const getRules = ( name = "" ) => {
        return [ { required: true, message: `This field cannot be left blank` } ]
    }
    return <Form.Item rules={ getRules( concatName[ 0 ] ) } name={ concatName } { ...props } />;
};



export const AddContentDescriptionData = () => {
    const dispatch = useDispatch()
    const page_id = useSelector( state => state.modal.transfer_id )
    const selected = useSelector( state => state.languages.selected )



    const onFinish = ( value ) => {
        const data = {
            can_show: false
        }
        for ( let prop in value ) {
            if ( value[ prop ] ) {
                data[ prop ] = value[ prop ]
            }
        }
        dispatch( addDescriptionContent( { data, page_id, language: selected } ) )
        dispatch( setModalType( null ) )
    };


    return <Card
        style={ { position: "relative", width: 370, textAlign: "center", display: "flex", flexDirection: "column" } }
        title={ `New content Description: ${selected.toUpperCase()}` }
    >
        <Form
            name="form_item_path" layout="vertical" onFinish={ onFinish }>
            <MyFormItem name="title" label="Title">
                <TextArea rows={3} placeholder="Title"/>
            </MyFormItem>
            <MyFormItem name="text" label="Text">
                <TextArea rows={6} placeholder="Description"/>
            </MyFormItem>

            <div style={ { width: "100%", display: "flex", justifyContent: "center" } }>
                <Button
                    type="default"
                    onClick={ () => dispatch( setModalType( null ) ) }
                    style={ { marginRight: "30px" } }
                >Cancel</Button>
                <Button type="primary" htmlType="submit">
                    Add
                </Button>
            </div>
        </Form>
    </Card>
}
