import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Card, Space, Select } from 'antd';
import { setModalType } from "../../../Redux/Slices/modal";
import { getPackages } from "../../../Redux/Slices/Education/Packages/asyncThunks";
import { v4 as uuidV4 } from "uuid"
import { addGroup } from "../../../Redux/Slices/Education/Group/asyncThunk";
import { getTeachers } from "../../../Redux/Slices/Education/Teachers/asyncThunks";

const { TextArea } = Input
const MyFormItemContext = React.createContext([]);

function toArr(str) {
    return Array.isArray(str) ? str : [str];
}

const MyFormItemGroup = ({ prefix, children }) => {
    const prefixPath = React.useContext(MyFormItemContext);
    const concatPath = React.useMemo(() => [...prefixPath, ...toArr(prefix)], [prefixPath, prefix]);
    return <MyFormItemContext.Provider value={concatPath}>{children}</MyFormItemContext.Provider>;
};
const MyFormItem = ({ name, ...props }) => {
    const prefixPath = React.useContext(MyFormItemContext);
    const concatName = name !== undefined ? [...prefixPath, ...toArr(name)] : undefined;
    return <Form.Item name={concatName} {...props} />;
};


export const AddGroup = () => {
    const dispatch = useDispatch()
    // const selected = useSelector(state => state.languages.selected)
    const packages = useSelector(state => state.packages.all)
    const teachers = useSelector(state => state.teachers.all)
    
    const packagesOption = packages.filter(elem => elem.groupId === null).map(item => {
        return {
            key: uuidV4(),
            label: item.name,
            value: item.id
        }
    })

    const [selectedPackage, selectPackage] = useState(null)

    const handleChange = (value) => {
        selectPackage(packages.filter(item => item.id === value.value)[0])
    };

    useEffect(() => {
        dispatch(getPackages())
        dispatch(getTeachers())
    }, [dispatch])


    const onFinish = (value) => {
        let data = {
            packageId: selectedPackage?.id
        }
        for (let prop in value) {
            if (value[prop]) {
                data[prop] = value[prop]
            }
        }
        dispatch(addGroup(data))
        dispatch(setModalType(null))
    };


    return <Card
        style={{ position: "relative", width: 370, textAlign: "center", display: "flex", flexDirection: "column" }}
        title={`New Group`}
    >
        <Form
            name="form_item_path" layout="vertical" onFinish={onFinish}>

            <MyFormItem name="name" label="Group name">
                <Input placeholder="Group name" />
            </MyFormItem>
            <Select
                labelInValue
                style={{
                    position: "relative",
                    width: '100%',
                }}
                placeholder="Packages"
                onChange={handleChange}
                options={packagesOption}
            />
            <MyFormItem name="packageName" label="Package name">
                <Input placeholder={selectedPackage?.name} disabled />
            </MyFormItem>
            <MyFormItem name="teacher" label="Teacher">
                <Input
                    placeholder={`${teachers.filter(teacher => teacher.id === selectedPackage?.teacherId)[0]?.name || ""
                        } ${teachers.filter(teacher => teacher.id === selectedPackage?.teacherId)[0]?.lastName || ""
                        } ${teachers.filter(teacher => teacher.id === selectedPackage?.teacherId)[0]?.position}`
                    }
                    disabled />
            </MyFormItem>
            <MyFormItem name="weekDays" label="Week Days">
                <Input placeholder={selectedPackage?.TimeLine?.weekDays} disabled />
            </MyFormItem>
            <MyFormItem name="duration" label="Duration">
                <Input placeholder={selectedPackage?.TimeLine?.duration} disabled />
            </MyFormItem>
            <MyFormItem name="StartEnd" label="Start & End">
                <Input
                    placeholder={`${selectedPackage?.TimeLine?.dayStartTime || ""} && ${selectedPackage?.TimeLine?.dayFinishTime || ""}`}
                    disabled />
            </MyFormItem>
            <MyFormItem name="classroom" label="Classroom">
                <Input placeholder={selectedPackage?.TimeLine?.classroom} disabled />
            </MyFormItem>
            {
                selectedPackage?.lessons?.map(lesson => {
                    return <MyFormItem key={uuidV4()} name="lesson" label="lesson">
                        <Input placeholder={lesson?.name} disabled />
                    </MyFormItem>
                })
            }

            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <Button
                    type="default"
                    onClick={() => dispatch(setModalType(null))}
                    style={{ marginRight: "30px" }}
                >Cancel</Button>
                <Button type="primary" htmlType="submit">
                    Add
                </Button>
            </div>
        </Form>
    </Card>
}


export default AddGroup