import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchingDataWithAxiosMiddleware } from "./fetch";
import dataControl from "../../Services/dataControl";
import Connections from "../../Services/connections";
import { setLoadingState } from "./loading";
import { notification } from "antd";


export const checkAccount = createAsyncThunk(
    "authentication/checkingAccount",
    async ( navigate, action ) => {
        action.dispatch( setLoadingState( true ) )
        try {
            const response = await fetchingDataWithAxiosMiddleware(
                "GET",
                Connections.CheckAccount()
            )

            if ( response.data.authenticate ) {
                navigate( '/' )
            } else {
                navigate( '/auth' )
                dataControl.removeToken()
            }
            action.dispatch( setLoadingState( false ) )
        } catch ( e ) {
            navigate( '/auth' )
            dataControl.removeToken()
            notification.error( {
                placement: 'topRight',
                message: e.message,
            } );
            action.dispatch( setLoadingState( false ) )
        }
    }
)


export const signUp = createAsyncThunk(
    "authentication/signUp",
    async ( payload, action ) => {
        try {
            action.dispatch( setLoadingState( true ) )
            const response = await fetchingDataWithAxiosMiddleware(
                "POST",
                Connections.SignUp(),
                {
                    ...payload.account,
                }
            )
            dataControl.saveToken( response.data.access_token )
            payload.navigate( '/' )
        } catch ( e ) {
            action.dispatch( setLoadingState( false ) )
            notification.error( {
                placement: 'topRight',
                message: "Check form entries",
            } );
        }
    }
)

export const authentication = createSlice( {
    name: "authentication",
    initialState: {},
    reducers: {},
    extraReducers: builder => {
    }
} )