import { DeleteOutlined, EditOutlined, MailOutlined, NodeIndexOutlined, UserAddOutlined } from '@ant-design/icons';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
    arrayMove,
    SortableContext,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import React, { useEffect } from 'react';
import { Badge, Button, notification, Table } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { setStudentsDataSource } from "../../Redux/Slices/Education/Students";
import { setModalTransferData, setModalType } from "../../Redux/Slices/modal";
import { getStudents } from "../../Redux/Slices/Education/Students/asyncThunks";
import { Row, rowSelection } from "../Common/tableUtils"
import { fetchingDataWithAxiosMiddleware } from "../../Redux/Slices/fetch";
import Connection from "../../Services/connections";


const sendEmail = async ( data ) => {
    try {
        const response = await fetchingDataWithAxiosMiddleware(
            "POST",
            Connection.SendVerificationLink(),
            data
        )
        return response.data
    } catch ( e ) {
        notification.error( {
            placement: 'topRight',
            message: e.message,
        } );
    }
}

const sendLogicTest = async ( data ) => {
    try {
        const response = await fetchingDataWithAxiosMiddleware(
            "POST",
            Connection.SendLogicTestLink(),
            data
        )
        return response.data
    } catch ( e ) {
        notification.error( {
            placement: 'topRight',
            message: e.message,
        } );
    }
}


const expandableColumns = [
    {
        title: 'Lastname',
        dataIndex: 'lastName',
    },
    {
        title: 'Phone',
        dataIndex: 'phone',
    },
    {
        title: 'Verified',
        dataIndex: 'verified',
        render: ( text ) => {
            if ( text ) {
                return <Badge status="success" text="Verified"/>
            }
            return <Badge status="error" text="Unverified"/>
        }
    },
    {
        title: 'Passed logic test',
        dataIndex: 'passed_test',
        render: ( text ) => {
            if ( text ) {
                return <Badge status="success" text="passed test"/>
            }
            return <Badge status="error" text="dont passed test"/>
        }
    },
    {
        title: 'Physical Cert',
        dataIndex: 'physicalCert',
        render: ( text ) => {
            if ( text ) {
                return <Badge status="success" text="YES"/>
            }
            return <Badge status="error" text="NO"/>
        }
    },
    {
        title: 'Registered',
        dataIndex: 'createdAt',
        key: 'register',
        render: ( text ) => {
            return text.substring( 0, text.indexOf( "T" ) )
        }
    },
];


const Students = () => {
    const dispatch = useDispatch()
    const students = useSelector( state => state.students.all )

    //TODO Verification mode issue in student


    useEffect( () => {
        dispatch( getStudents() )
    }, [ dispatch ] )


    const columns = [
        {
            key: 'sort',
        },
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
        },
        {
            //TODO check send email settings
            title: 'Action',
            render: ( e ) => {
                return <>
                    { !e.passed_test ?
                        <NodeIndexOutlined
                            onClick={ () => {
                                //TODO CHECK HERE
                                sendLogicTest( { email: e.email, name: e.name, lastName: e.lastName } ).then( () => {
                                    notification.success( {
                                        placement: 'topRight',
                                        message: "successfully sent",
                                    } );
                                } ).catch( e => {
                                    notification.error( {
                                        placement: 'topRight',
                                        message: e.message,
                                    } );
                                } )
                            } } style={ { cursor: "pointer", marginRight: "20px" } }
                        /> : <MailOutlined
                            onClick={ () => {
                                //TODO CHECK HERE
                                sendEmail( { email: e.email, name: e.name, lastName: e.lastName } ).then( () => {
                                    notification.success( {
                                        placement: 'topRight',
                                        message: "successfully sent",
                                    } );
                                } ).catch( e => {
                                    notification.error( {
                                        placement: 'topRight',
                                        message: e.message,
                                    } );
                                } )
                            } } style={ { cursor: "pointer", marginRight: "20px" } }
                        />
                    }
                    <EditOutlined
                        onClick={ () => {
                            dispatch( setModalTransferData( {
                                modalType: "editStudent",
                                transfer_id: e.id,
                                process_type: "student",
                            } ) )
                        } } style={ { cursor: "pointer", marginRight: "20px" } }
                    />
                    <DeleteOutlined
                        onClick={ () => {
                            dispatch( setModalTransferData( {
                                modalType: "delete",
                                transfer_id: e.id,
                                process_type: "student",
                            } ) )
                        } } style={ { cursor: "pointer", color: "red" } }/>
                </>
            }
        },
    ];


    const onDragEnd = ( { active, over } ) => {
        if ( active.id !== over?.id ) {
            const activeIndex = students?.findIndex( ( i ) => i.id === active.id );
            const overIndex = students?.findIndex( ( i ) => i.id === over?.id );
            dispatch( setStudentsDataSource( arrayMove( students, activeIndex, overIndex ) ) )
        }
    };

    return (
        <DndContext modifiers={ [ restrictToVerticalAxis ] } onDragEnd={ onDragEnd }>
            <Button
                onClick={ () => dispatch( setModalType( "addStudent" ) ) }
                type="primary"
                style={ { margin: "10px 0 20px 5px" } }
            >Add Student <UserAddOutlined/> </Button>
            <SortableContext
                items={ students?.map( ( i ) => i.id ) || [] }
                strategy={ verticalListSortingStrategy }
            >
                <Table
                    components={ {
                        body: {
                            row: Row,
                        },
                    } }
                    expandable={ {
                        expandedRowRender: ( record ) => {
                            return <Table
                                rowKey="id"
                                columns={ expandableColumns }
                                dataSource={ [ { ...record } ] }
                                pagination={ false }
                            />;
                        },
                    } }
                    rowSelection={ {
                        type: "checkbox",
                        ...rowSelection,
                    } }
                    rowKey="id"
                    columns={ columns }
                    dataSource={ students || [] }
                />
            </SortableContext>
        </DndContext>
    );
};
export default Students;