import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Card, Space, Select, Checkbox } from 'antd';
import { setModalType } from "../../../Redux/Slices/modal";
import { editContent } from "../../../Redux/Slices/Content/asyncThunk";

const { TextArea } = Input
const MyFormItemContext = React.createContext( [] );

function toArr( str ) {
    return Array.isArray( str ) ? str : [ str ];
}

const MyFormItemGroup = ( { prefix, children } ) => {
    const prefixPath = React.useContext( MyFormItemContext );
    const concatPath = React.useMemo( () => [ ...prefixPath, ...toArr( prefix ) ], [ prefixPath, prefix ] );
    return <MyFormItemContext.Provider value={ concatPath }>{ children }</MyFormItemContext.Provider>;
};
const MyFormItem = ( { name, ...props } ) => {
    const prefixPath = React.useContext( MyFormItemContext );
    const concatName = name !== undefined ? [ ...prefixPath, ...toArr( name ) ] : undefined;
    return <Form.Item name={ concatName } { ...props } />;
};


export const EditContentData = () => {
    const dispatch = useDispatch()

    const [ inLive, setLiveState ] = useState( null )

    const selected = useSelector( state => state.languages.selected )
    const contents = useSelector( state => state.content.all )
    const transfer_id = useSelector( state => state.modal.transfer_id )
    const selected_content = contents?.filter( content => content.id === transfer_id )[ 0 ]


    const onFinish = ( value ) => {
        let data = {}
        for ( let prop in value ) {
            if (
                value[ prop ] !== undefined &&
                value[ prop ] !== null && prop !== "title"
            ) {
                data[ prop ] = value[ prop ]
            }
            if ( prop === "title" && value[ prop ] !== selected_content.title[ selected ] ) {
                data[ prop ] = value[ prop ]
            }
        }
        if ( inLive !== null && inLive !== selected_content.can_show ) {
            data.can_show = inLive
        }
        dispatch( editContent( { data, language: selected, id: transfer_id } ) )
        dispatch( setModalType( null ) )
    };


    return <Card
        style={ { position: "relative", width: 370, textAlign: "center", display: "flex", flexDirection: "column" } }
        title={ `Content ID: ${ transfer_id }` }
        extra={ <Checkbox
            defaultChecked={ selected_content?.can_show }
            onChange={ ( e ) => setLiveState( e.target.checked ) }
        >
            In Live
        </Checkbox> }
    >
        <Form
            initialValues={ {
                [ "name" ]: selected_content.name,
                [ "title" ]: selected_content?.title && selected_content.title[ selected ],
            } }

            name="form_item_path" layout="vertical" onFinish={ onFinish }>
            <MyFormItem name="name" label="Page name">
                <Input placeholder={ selected_content.page } disabled/>
            </MyFormItem>
            <MyFormItem name="subName" label="Section name">
                <Input placeholder={ selected_content.section_name } disabled/>
            </MyFormItem>
            <MyFormItem name="title" label="Title">
                <TextArea rows={3}/>
            </MyFormItem>

            <div style={ { width: "100%", display: "flex", justifyContent: "center" } }>
                <Button
                    type="default"
                    onClick={ () => dispatch( setModalType( null ) ) }
                    style={ { marginRight: "30px" } }
                >Cancel</Button>
                <Button type="primary" htmlType="submit">
                    Confirm
                </Button>
            </div>
        </Form>
    </Card>
}
