import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import store from "./Redux/store";
import { BrowserRouter } from "react-router-dom";
import "./i18n"


const root = ReactDOM.createRoot( document.getElementById( 'root' ) );
root.render(
    // <React.StrictMode>
    <Provider store={ store }>
        <BrowserRouter>
            <Suspense fallback={<div>Loading type ...</div>}>
                <App/>
            </Suspense>
        </BrowserRouter>
    </Provider>
    // </React.StrictMode>
);
reportWebVitals();
