import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Card, Space, Select } from 'antd';
import { setModalType } from "../../../Redux/Slices/modal";
import { v4 as uuidV4 } from "uuid"
import { getTimeLines } from "../../../Redux/Slices/Education/TimeLine/asyncThunks";
import { getLessons } from "../../../Redux/Slices/Education/Lessons/asyncThunks";
import { getTeachers } from "../../../Redux/Slices/Education/Teachers/asyncThunks";
import { addPackage } from "../../../Redux/Slices/Education/Packages/asyncThunks";

const { TextArea } = Input
const MyFormItemContext = React.createContext([]);

function toArr(str) {
    return Array.isArray(str) ? str : [str];
}

const MyFormItem = ({ name, ...props }) => {
    const prefixPath = React.useContext(MyFormItemContext);
    const concatName = name !== undefined ? [...prefixPath, ...toArr(name)] : undefined;
    return <Form.Item name={concatName} {...props} />;
};


export const AddPackage = () => {
    const dispatch = useDispatch()
    const times = useSelector(state => state.timeLines.all)
    const lessons = useSelector(state => state.lessons.all)
    const teachers = useSelector(state => state.teachers.all)
    const [selectedData, selectData] = useState()
    const [addedLessons, setLesson] = useState(null)
    const [addedTeacherId, setTeacher] = useState(null)


    useEffect(() => {
        dispatch(getTimeLines())
    }, [dispatch])

    const teacherOptions = teachers.map(teacher => {
        return {
            key: uuidV4(),
            label: `${teacher.name} ${teacher.lastName} || ${teacher.position}`,
            value: `${teacher.id}`,
        }
    })
    const timeOptions = times?.filter(time => time?.packages.length === 0)?.map(time => {
        return {
            key: uuidV4(),
            label: `${time.weekDays} ${time.dayStartTime}`,
            value: `${time.id}`,
        }
    })

    const lessonsOptions = lessons?.map(lesson => {
        return {
            key: uuidV4(),
            label: lesson.name,
            value: `${lesson.id}`,
        }
    })


    useEffect(() => {
        dispatch(getTimeLines())
        dispatch(getLessons())
        dispatch(getTeachers())
    }, [dispatch])


    const onFinish = (value) => {
        let data = {
            timeLineId: selectedData.time.id,
            teacherId: selectedData.time.teacherId
        }
        if (addedTeacherId) {
            data.teacherId = addedTeacherId
        }
        for (let prop in value) {
            if (value[prop]) {
                data[prop] = value[prop]
            }
        }

        if (addedLessons.length) {
            data.lessons = addedLessons
            dispatch(addPackage(data))
            dispatch(setModalType(null))
        }
    };

    const handleChangeStartEndTime = (data) => {
        selectData({
            time: times.filter(time => time.id === parseInt(data?.value))[0]
        })
    }

    const handleChangeLessons = (data) => {
        setLesson(data)
    }

    const handleChangeTeacher = (data) => {
        setTeacher(parseInt(data?.value))
    }


    return <Card
        style={{ position: "relative", width: 370, textAlign: "center", display: "flex", flexDirection: "column" }}
        title={`New Package`}
    >
        <Form
            name="form_item_path" layout="vertical" onFinish={onFinish}>

            <MyFormItem name="name" label="Package">
                <Input placeholder="Package name" />
            </MyFormItem>

            <Select
                labelInValue
                allowClear
                style={{
                    position: "relative",
                    margin: "25px 0",
                    width: '100%',
                }}
                placeholder="Date"
                onChange={handleChangeStartEndTime}
                options={timeOptions}
            />


            <MyFormItem name="duration" label="Duration">
                <Input placeholder={selectedData?.time?.duration} disabled />
            </MyFormItem>

            <MyFormItem name="classroom" label="Classroom">
                <Input placeholder={selectedData?.time?.classroom} disabled />
            </MyFormItem>

            {
                selectedData?.time?.teacherId
                && <MyFormItem name="teacherId" label="Teacher">
                    <Input placeholder={
                        `${teachers.filter(teacher => teacher.id === selectedData?.time?.teacherId)[0]?.name
                        } ${teachers.filter(teacher => teacher.id === selectedData?.time?.teacherId)[0]?.lastName
                        } || ${teachers.filter(teacher => teacher.id === selectedData?.time?.teacherId)[0]?.position
                        }`
                    } disabled />
                </MyFormItem>
            }

            <Select
                mode="tags"
                style={{
                    position: "relative",
                    width: '100%',
                    margin: "10px 0 20px 0"
                }}
                placeholder="Lessons"
                onChange={handleChangeLessons}
                options={lessonsOptions}
            />


            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <Button
                    type="default"
                    onClick={() => dispatch(setModalType(null))}
                    style={{ marginRight: "30px" }}
                >Cancel</Button>
                <Button type="primary" htmlType="submit">
                    Add
                </Button>
            </div>
        </Form>
    </Card>
}
