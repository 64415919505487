import { DeleteOutlined, EditOutlined, MailOutlined, NodeIndexOutlined, UserAddOutlined } from '@ant-design/icons';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
    arrayMove,
    SortableContext,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import React, { useEffect } from 'react';
import { Badge, Button, notification, Table, Typography } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { setStudentsDataSource } from "../../Redux/Slices/Education/Students";
import { setModalTransferData, setModalType } from "../../Redux/Slices/modal";
import { Row, rowSelection } from "../Common/tableUtils"
import { getCustomers } from "../../Redux/Slices/Customer/asyncThunks";

const { Paragraph } = Typography;


// const sendEmail = async ( data ) => {
//     try {
//         const response = await fetchingDataWithAxiosMiddleware(
//             "POST",
//             Connection.SendVerificationLink(),
//             data
//         )
//         return response.data
//     } catch ( e ) {
//         console.log( e.message )
//     }
// }


const expandableColumns = [
    {
        key: "sort"
    },
    {
        title: 'Lastname',
        dataIndex: 'lastName',
    },
    {
        title: 'Phone',
        dataIndex: 'phone',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        render: ( text ) => {
            return <Badge status={ text === "waiting" ? "error" : text === "connected" ? "warning" : "success" }
                          text={ text.charAt( 0 ).toUpperCase() + text.slice( 1 ) }/>
        }
    },

];


const Customers = () => {
    const dispatch = useDispatch()
    const customers = useSelector( state => state.customers.all )



    useEffect( () => {
        dispatch( getCustomers() )
    }, [ dispatch ] )


    const columns = [
        {
            key: 'sort',
        },
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            render: (text) => {
                return  <Paragraph
                    style={ { alignItems: "center" } }
                    copyable={ { text } }
                >

                    {text}
                </Paragraph>
            }
        },
        {
            //TODO check send email settings
            title: 'Action',
            render: ( e ) => {
                return <>
                    {/*<MailOutlined*/}
                    {/*    onClick={ () => {*/}
                    {/*        //TODO CHECK HERE*/}
                    {/*        sendEmail( { email: e.email, name: e.name, lastName: e.lastName } ).then( () => {*/}
                    {/*            notification.success( {*/}
                    {/*                placement: 'topRight',*/}
                    {/*                message: "successfully sent",*/}
                    {/*            } );*/}
                    {/*        } ).catch( e => {*/}
                    {/*            notification.error( {*/}
                    {/*                placement: 'topRight',*/}
                    {/*                message: e.message,*/}
                    {/*            } );*/}
                    {/*        } )*/}
                    {/*    } } style={ { cursor: "pointer", marginRight: "20px" } }*/}
                    {/*/>*/}
                    <EditOutlined
                        key="editOutlined"
                        onClick={ () => {
                            dispatch( setModalTransferData( {
                                modalType: "editCustomer",
                                transfer_id: e.id,
                                process_type: "customer",
                            } ) )
                        } } style={ { cursor: "pointer", marginRight: "20px" } }
                    />
                    <DeleteOutlined
                        key="deleteOutlined"
                        onClick={ () => {
                            dispatch( setModalTransferData( {
                                modalType: "delete",
                                transfer_id: e.id,
                                process_type: "customer",
                            } ) )
                        } } style={ { cursor: "pointer", color: "red" } }/>
                </>
            }
        },
    ];


    const onDragEnd = ( { active, over } ) => {
        if ( active.id !== over?.id ) {
            const activeIndex = customers?.findIndex( ( i ) => i.id === active.id );
            const overIndex = customers?.findIndex( ( i ) => i.id === over?.id );
            dispatch( setStudentsDataSource( arrayMove( customers, activeIndex, overIndex ) ) )
        }
    };

    return (
        <DndContext modifiers={ [ restrictToVerticalAxis ] } onDragEnd={ onDragEnd }>
            <Button
                onClick={ () => dispatch( setModalType( "addCustomer" ) ) }
                type="primary"
                style={ { margin: "10px 0 20px 5px" } }
            >Add Customer <UserAddOutlined/> </Button>
            <SortableContext
                items={ customers?.map( ( i ) => i.id ) || [] }
                strategy={ verticalListSortingStrategy }
            >
                <Table
                    components={ {
                        body: {
                            row: Row,
                        },
                    } }
                    expandable={ {
                        expandedRowRender: ( record ) => {
                            return <Table rowKey="id" columns={ expandableColumns } dataSource={ [ { ...record } ] }
                                          pagination={ false }/>;
                        },
                    } }
                    rowSelection={ {
                        type: "checkbox",
                        ...rowSelection,
                    } }
                    rowKey="id"
                    columns={ columns }
                    dataSource={ customers || [] }
                />
            </SortableContext>
        </DndContext>
    );
};
export default Customers;