import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Card, Space, Select, Radio } from 'antd';
import { setModalType } from "../../../Redux/Slices/modal";
import { useTranslation } from "react-i18next";
import { addQuestion } from "../../../Redux/Slices/Education/Exams/asyncThunk";

const { TextArea } = Input
const MyFormItemContext = React.createContext( [] );

function toArr( str ) {
    return Array.isArray( str ) ? str : [ str ];
}

const MyFormItemGroup = ( { prefix, children } ) => {
    const prefixPath = React.useContext( MyFormItemContext );
    const concatPath = React.useMemo( () => [ ...prefixPath, ...toArr( prefix ) ], [ prefixPath, prefix ] );
    return <MyFormItemContext.Provider value={ concatPath }>{ children }</MyFormItemContext.Provider>;
};
const MyFormItem = ( { name, ...props } ) => {
    const prefixPath = React.useContext( MyFormItemContext );
    const concatName = name !== undefined ? [ ...prefixPath, ...toArr( name ) ] : undefined;
    return <Form.Item name={ concatName } { ...props } />;
};


export const AddExamQuestionData = () => {
    const dispatch = useDispatch()
    const languages = useSelector( state => state.languages.data )
    const langId = useSelector( state => state.languages.selected )
    const transfer_id = useSelector( state => state.modal.transfer_id )
    const selected_language = languages.filter( language => language.id === langId )[ 0 ]

    const onFinish = ( value ) => {
        dispatch( addQuestion( { ...value, langId, examId: transfer_id } ) )
        dispatch( setModalType( null ) )
    };


    return <Card
        style={ { position: "relative", width: 370, textAlign: "center", display: "flex", flexDirection: "column" } }
        title={ `Exam ID: ${transfer_id} - ${ selected_language.name }` }
    >
        <Form
            name="form_item_path" layout="vertical" onFinish={ onFinish }>
            <MyFormItem name="title" label="title">
                <Input placeholder="Ex. Question 1"/>
            </MyFormItem>
            <MyFormItem name="question" label="Question">
                <TextArea rows={ 3 } placeholder="Write your question here."/>
            </MyFormItem>

            <MyFormItem label="Correct Answer" name="correct_answer">
                <Radio.Group>
                    <Radio.Button value="answer_a">A</Radio.Button>
                    <Radio.Button value="answer_b">B</Radio.Button>
                    <Radio.Button value="answer_c">C</Radio.Button>
                    <Radio.Button value="answer_d">D</Radio.Button>
                </Radio.Group>
            </MyFormItem>
            <MyFormItem name="answer_a" label="Answer_a">
                <Input placeholder="A) Your answer here"/>
            </MyFormItem>
            <MyFormItem name="answer_b" label="Answer_b">
                <Input placeholder="B) Your answer here"/>
            </MyFormItem>
            <MyFormItem name="answer_c" label="Answer_c">
                <Input placeholder="C) Your answer here"/>
            </MyFormItem>
            <MyFormItem name="answer_d" label="Answer_d">
                <Input placeholder="D) Your answer here"/>
            </MyFormItem>

            <div style={ { width: "100%", display: "flex", justifyContent: "center" } }>
                <Button
                    type="default"
                    onClick={ () => dispatch( setModalType( null ) ) }
                    style={ { marginRight: "30px" } }
                >Cancel</Button>
                <Button type="primary" htmlType="submit">
                    Add
                </Button>
            </div>
        </Form>
    </Card>
}
