import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchingDataWithAxiosMiddleware } from "../../fetch";
import Connection from "../../../../Services/connections";
import { setLoadingState } from "../../loading";
import { notification } from "antd";

export const printQr = createAsyncThunk(
    'qr/printQr',
    async ( data, actions ) => {
        actions.dispatch( setLoadingState( true ) )
        try {
            const response = await fetchingDataWithAxiosMiddleware(
                "GET",
                Connection.PrintQR( data.id ),
            );
            actions.dispatch( setLoadingState( false ) )
            notification.success( {
                placement: 'topRight',
                message: "Printed",
            } );

            return response.data.qrs;
        } catch ( e ) {
            actions.dispatch( setLoadingState( false ) )
            notification.error( {
                placement: 'topRight',
                message: e.message,
            } );
        }
    }
);


export const getQrs = createAsyncThunk(
    'qr/getAll',
    async ( _, actions ) => {
        try {
            actions.dispatch( setLoadingState( true ) )
            const response = await fetchingDataWithAxiosMiddleware(
                "GET",
                Connection.QrsAll()
            );
            actions.dispatch( setLoadingState( false ) )
            return response.data.qrs;
        } catch ( e ) {
            actions.dispatch( setLoadingState( false ) )
            notification.error( {
                placement: 'topRight',
                message: e.message,
            } );
        }
    }
);

export const generateQr = createAsyncThunk(
    'qr/generateQr',
    async ( data, actions ) => {
        try {
            actions.dispatch( setLoadingState( true ) )
            const formData = new FormData();
            formData.append( "qr_type", data.type )
            const response = await fetchingDataWithAxiosMiddleware(
                "POST",
                Connection.GenerateQR(),
                formData
            );

            if ( response.data.message === "success" ) {
                notification.success( {
                    placement: 'topRight',
                    message: "Generated successfully",
                } );
                actions.dispatch( getQrs() )
            }
        } catch ( e ) {
            notification.error( {
                placement: 'topRight',
                message: e.message,
            } );
            actions.dispatch( setLoadingState( false ) )
        }
    }
);

export const deleteQr = createAsyncThunk(
    'qr/deleteQr',
    async ( data, actions ) => {
        try {
            actions.dispatch( setLoadingState( true ) )
            const response = await fetchingDataWithAxiosMiddleware(
                "DELETE",
                Connection.DeleteQR( data.id ),
            );

            if ( response.data.message === "success" ) {
                notification.success( {
                    placement: 'topRight',
                    message: "Deleted successfully",
                } );
                actions.dispatch( getQrs() )
            }
        } catch ( e ) {
            notification.error( {
                placement: 'topRight',
                message: e.message,
            } );
            actions.dispatch( setLoadingState( false ) )
        }
    }
);

