import './App.css';
import { ConfigProvider } from "antd";
import Auth from "./Components/Auth";
import { Route } from "react-router-dom";
import { Routes } from "react-router";
import Main from "./Components/Main";
import VerifyQR from "./Components/QR/verifyQR";
import Modal from "./Components/Modal";
import 'react-quill/dist/quill.snow.css'; // Import Quill's snow theme CSS


function App() {
    const allRoutes = [
        { id: 1, path: "/auth", element: <Auth/>, name: "Auth" },
        { id: 2, path: "/", element: <Main/>, name: "Main" },
        { id: 3, path: "/verify/:token", element: <VerifyQR/>, name: "VerifyQR" },
        // { id: 4, path: "/test", element: <Test/>, name: "Test" },
    ]

    return (
        <ConfigProvider
            theme={ {
                token: {
                    colorPrimary: "#4DB671",
                    fontFamily: "Azo-Sans, sans-serif",
                },
            } }
        >
            <Routes>
                { allRoutes.map( ( route ) => {
                    return (
                        <Route key={ route.id } path={ route.path } element={ route.element }/>
                    );
                } ) }
            </Routes>
            <Modal/>
        </ConfigProvider>
    );
}

export default App;
