import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Card, Checkbox, Select, InputNumber } from 'antd';
import { setModalType } from "../../../Redux/Slices/modal";
import { getGroups } from "../../../Redux/Slices/Education/Group/asyncThunk";
import { editStudents } from "../../../Redux/Slices/Education/Students/asyncThunks";

const MyFormItemContext = React.createContext( [] );

function toArr( str ) {
    return Array.isArray( str ) ? str : [ str ];
}

// const MyFormItemGroup = ( { prefix, children } ) => {
//     const prefixPath = React.useContext( MyFormItemContext );
//     const concatPath = React.useMemo( () => [ ...prefixPath, ...toArr( prefix ) ], [ prefixPath, prefix ] );
//     return <MyFormItemContext.Provider value={ concatPath }>{ children }</MyFormItemContext.Provider>;
// };
const MyFormItem = ( { name, ...props } ) => {
    const prefixPath = React.useContext( MyFormItemContext );
    const concatName = name !== undefined ? [ ...prefixPath, ...toArr( name ) ] : undefined;

    const getRules = ( name = "" ) => {
        if ( name === "email" ) {
            return [
                {
                    required: true,
                    type: "email",
                    message: "The input is not valid E-mail!",
                },
            ]
        }
        if ( name === "phone" ) {
            return [
                {
                    required: true,
                    type: "number",
                    message: "This field cannot be left blank",
                },
            ]
        }
        if ( name === "package" ) {
            return [
                {
                    required: false
                }
            ]
        }
        return [ { required: true, message: `This field cannot be left blank` } ]
    }


    return <Form.Item uploadedImage={ getRules( concatName[ 0 ] ) } name={ concatName } { ...props } />;
};


export const EditStudentData = () => {
    const dispatch = useDispatch()
    const students = useSelector( state => state.students.all )
    const transfer_id = useSelector( state => state.modal.transfer_id )
    const selected_student = students.filter( student => student.id === transfer_id )[ 0 ]
    const [ isVerified, setVerificationState ] = useState( false )
    const [ physicalCert, setPhysicalCert ] = useState( false )
    const groups = useSelector( state => state.groups.all )
    const groupsOptions = groups.map( ( elem ) => {
        return {
            key: elem.id + elem.name,
            label: elem.name,
            value: `${ elem.id }`
        }
    } )

    const [ changedGroup, setGroup ] = useState()

    useEffect( () => {
        if ( !groups.length ) {
            dispatch( getGroups() )
        }
    }, [ dispatch, groups.length ] )


    const handleGroupChange = ( data ) => {
        const group = groups.filter( group => group.id === parseInt( data?.value ) )[ 0 ]
        setGroup( group )
    }

    const onFinish = ( value ) => {
        let data = {}
        if ( changedGroup?.id ) {
            data.groupId = changedGroup?.id
        }
        if ( physicalCert ) {
            data.physicalCert = physicalCert
        }
        for ( let prop in value ) {
            if ( value[ prop ]?.toString() !== selected_student[ prop ] ) {
                data[ prop ] = value[ prop ]
            }
        }
        if ( Object.keys( data ).length !== 0 ) {
            data.verified = isVerified
            data.id = transfer_id
            dispatch( editStudents( data ) )
            dispatch( setModalType( null ) )
        }
    };

    return <Card title={ `Edit Student ID: ${ transfer_id }` }
                 extra={
                     <Checkbox
                         disabled={ selected_student?.verified }
                         defaultChecked={ selected_student?.verified }
                         onChange={ ( e ) => setVerificationState( e.target.checked ) }
                     >
                         { selected_student?.verified ? "Verified" : "Verify ?" }
                     </Checkbox>
                 }
                 style={ {
                     width: 370,
                     // display: "flex",
                     // textAlign: "center",
                     // flexDirection: "column"
                 } }>
        <Form
            initialValues={ {
                "name": selected_student.name,
                "lastName": selected_student.lastName,
                "email": selected_student.email,
                "phone": parseInt( selected_student.phone ),
            } }
            name="form_item_path" layout="vertical" onFinish={ onFinish }
        >
            <Checkbox
                disabled={ selected_student?.physicalCert }
                defaultChecked={ selected_student?.physicalCert }
                style={ { marginBottom: "20px" } }
                onChange={ ( e ) => setPhysicalCert( e.target.checked ) }
            >
                { selected_student?.physicalCert ? "Physical certified" : "Physical certify ?" }
            </Checkbox>
            <MyFormItem name="name" label="First Name">
                <Input/>
            </MyFormItem>
            <MyFormItem name="lastName" label="Last Name">
                <Input/>
            </MyFormItem>
            <MyFormItem name="email" label="Email">
                <Input/>
            </MyFormItem>
            <MyFormItem name="phone" label="Phone">
                <InputNumber
                    minLength={ 8 }
                    maxLength={ 9 }
                    style={ { width: "100%" } }
                />
            </MyFormItem>

            <Select
                labelInValue
                allowClear
                style={ {
                    position: "relative",
                    margin: "15px 0",
                    width: '100%',
                } }
                placeholder={ groups?.filter( group => group.id === selected_student?.groupId )[ 0 ]?.name || "Group" }
                onChange={ handleGroupChange }
                options={ groupsOptions }
            />

            <MyFormItem label="Package" name="package">
                <Input disabled placeholder={
                    changedGroup?.package?.name || groups?.filter( group => group.id === selected_student?.groupId )[ 0 ]?.package?.name
                }/>
            </MyFormItem>


            <div style={ { width: "100%", display: "flex", justifyContent: "center" } }>
                <Button
                    type="default"
                    onClick={ () => dispatch( setModalType( null ) ) }
                    style={ { marginRight: "30px" } }
                >Cancel</Button>
                <Button type="primary" htmlType="submit">
                    Save
                </Button>
            </div>
        </Form>
    </Card>
}
