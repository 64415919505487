import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Card, Space, Select, InputNumber, Upload, notification } from 'antd';
import { setModalType } from "../../../Redux/Slices/modal";
import { getPackages } from "../../../Redux/Slices/Education/Packages/asyncThunks";
import { v4 as uuidV4 } from "uuid"
import { addLesson } from "../../../Redux/Slices/Education/Lessons/asyncThunks";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

const { TextArea } = Input

const MyFormItemContext = React.createContext( [] );

function toArr( str ) {
    return Array.isArray( str ) ? str : [ str ];
}

const MyFormItem = ( { name, ...props } ) => {
    const prefixPath = React.useContext( MyFormItemContext );
    const concatName = name !== undefined ? [ ...prefixPath, ...toArr( name ) ] : undefined;
    return <Form.Item name={ concatName } { ...props } />;
};

const getBase64 = ( img, callback ) => {
    const reader = new FileReader();
    reader.addEventListener( 'load', () => callback( reader.result ) );
    reader.readAsDataURL( img );
};


const beforeUpload = ( file ) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if ( !isJpgOrPng ) {
        notification.error( {
            placement: 'topRight',
            message: "Image size must be 1MB or less.",
        } );
    }
    const isLt1M = file.size / 1024 / 1024 < 1; // Check if file size is less than 1MB
    if ( !isLt1M ) {
        notification.error( {
            placement: 'topRight',
            message: 'Image size must be 1MB or less.',
        } );
    }
    return isJpgOrPng && isLt1M;
};


export const AddLesson = () => {
    const dispatch = useDispatch()
    const selected = useSelector( state => state.languages.selected )
    const [ loading, setLoading ] = useState( false );
    const [ uploadedImage, setUploadedImage ] = useState( { url: "", file: null } );


    const onFinish = ( value ) => {
        let data = {}
        for ( let prop in value ) {
            if ( value[ prop ] && prop !== "icon" ) {
                data[ prop ] = value[ prop ]
            }
            if (prop === "icon"){
                data.the = "Lessons"
                data.photo = value[prop]?.file?.originFileObj
            }
        }
        data.language = selected
        dispatch( addLesson( data ) )
        dispatch( setModalType( null ) )
    };

    const handleChange = ( info ) => {
        if ( info.file ) {
            // Get this url from response in real world.
            getBase64( info.file.originFileObj, ( url ) => {
                setLoading( false );
                setUploadedImage( { url, file: info.file.originFileObj } );
            } );
        }
    };

    const uploadButton = (
        <button style={ { border: 0, background: 'none' } } type="button">
            { loading ? <LoadingOutlined/> : <PlusOutlined/> }
            <div style={ { marginTop: 8 } }>Upload</div>
        </button>
    )

    return <Card
        style={ { position: "relative", width: 370, textAlign: "center", display: "flex", flexDirection: "column" } }
        title={ `New Lesson: ${ selected.toUpperCase() }` }
    >
        <Form
            name="form_item_path" layout="vertical" onFinish={ onFinish }>

            <MyFormItem name="name" label="Lesson">
                <Input placeholder="Lesson name" required/>
            </MyFormItem>

            <MyFormItem name="title" label="Title">
                <Input placeholder="Lesson title" required/>
            </MyFormItem>

            <MyFormItem name="description" label="Description">
                <TextArea rows={ 3 } placeholder="Lesson description" required/>
            </MyFormItem>

            <MyFormItem name="icon" label="Icon">
                <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={ false }
                    // TODO request post error here
                    // action="any url here"
                    accept=".jpg, .png, .jpeg"
                    onChange={ handleChange }
                    style={ { width: '300px' } }
                    beforeUpload={ beforeUpload }
                >
                    { uploadedImage.url ?
                        <img src={ uploadedImage.url } alt="avatar" style={ { width: '100%' } }/> : uploadButton }
                </Upload>
            </MyFormItem>

            <div style={ { width: "100%", display: "flex", justifyContent: "Space-between" } }>
                <MyFormItem name="duration" label="Duration">
                    <InputNumber
                        max={ 30 }
                        maxLength={ 2 }
                        style={ { width: "100%" } }
                        placeholder="1"
                        required
                    />
                </MyFormItem>
                <MyFormItem name="durationPrefix" label=" ">
                    <Select
                        // defaultValue="lucy"
                        placeholder="1 Month"
                        // onChange={ handleChange }
                        style={ {
                            maxWidth: "140px"
                        } }
                        options={ [
                            { value: 'd', label: 'Days' },
                            { value: 'w', label: 'Week' },
                            { value: 'm', label: 'Month' },
                            { value: 'y', label: 'Year' },
                        ] }
                        required
                    />
                </MyFormItem>
            </div>


            <div style={ { width: "100%", display: "flex", justifyContent: "Space-between" } }>
                <MyFormItem name="price" label="Price">
                    <InputNumber style={ { width: "100%" } } placeholder="Lesson price" required/>
                </MyFormItem>
                <MyFormItem name="" label=" ">
                    <Select
                        disabled
                        style={ {
                            position: "relative",
                            // margin: "25px 0",
                            width: '80px',
                        } }
                        placeholder={ selected === "en" ? "USD" : "AMD" }
                    />
                </MyFormItem>
            </div>

            <div style={ { width: "100%", display: "flex", justifyContent: "center" } }>
                <Button
                    type="default"
                    onClick={ () => dispatch( setModalType( null ) ) }
                    style={ { marginRight: "30px" } }
                >Cancel</Button>
                <Button type="primary" htmlType="submit">
                    Add
                </Button>
            </div>
        </Form>
    </Card>
}
