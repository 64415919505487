import { createSlice } from '@reduxjs/toolkit'
// import { fetchingDataWithAxiosMiddleware } from "./fetch"; //next js redux toolkit
// import Connection from "../../Services/connections"


export const main = createSlice( {
    name: 'main',
    initialState: {
        content: [
            {
                name: "Student",

            }
        ],
    },
    reducers: {},
    extraReducers: ( builder ) => {}
} )

export const {} = main.actions
export default main.reducer