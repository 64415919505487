import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchingDataWithAxiosMiddleware } from "../fetch";
import Connection from "../../../Services/connections";
import { setLoadingState } from "../loading";
import { notification } from "antd";

export const getCustomers = createAsyncThunk(
    'customers/getAll',
    async ( _, actions ) => {
        try {
            actions.dispatch( setLoadingState( true ) )
            const response = await fetchingDataWithAxiosMiddleware(
                "GET",
                Connection.Customers(),
            );
            actions.dispatch( setLoadingState( false ) )
            return response.data.customers;
        } catch ( e ) {
            actions.dispatch( setLoadingState( false ) )
            notification.error( {
                placement: 'topRight',
                message: e.message,
            } );
        }
    }
);

export const addCustomer = createAsyncThunk(
    'customers/add',
    async ( data, actions ) => {
        actions.dispatch( setLoadingState( true ) )
        try {
            const formData = new FormData()
            for ( let prop in data ) {
                if ( data[ prop ] ) {
                    formData.append( prop, data[ prop ] )
                }
            }

            const response = await fetchingDataWithAxiosMiddleware(
                "POST",
                Connection.Customers(),
                formData
            );

            if ( response.data.message === "success" ) {
                notification.success( {
                    placement: 'topRight',
                    message: "Added successfully",
                } );
                return actions.dispatch( getCustomers() )
            }
        } catch ( e ) {
            actions.dispatch( setLoadingState( false ) )
            notification.error( {
                placement: 'topRight',
                message: e.message,
            } );
        }
    }
);

export const editCustomer = createAsyncThunk(
    'customers/edit',
    async ( data, actions ) => {
        actions.dispatch( setLoadingState( true ) )
        try {
            const formData = new FormData()
            for ( let prop in data ) {
                if ( data[ prop ] ) {
                    formData.append( prop, data[ prop ] )
                }
            }

            const response = await fetchingDataWithAxiosMiddleware(
                "PUT",
                Connection.Customers(),
                formData
            );

            if ( response.data.message === "success" ) {
                notification.success( {
                    placement: 'topRight',
                    message: "Edited successfully",
                } );
                return actions.dispatch( getCustomers() )
            }
        } catch ( e ) {
            actions.dispatch( setLoadingState( false ) )
            notification.error( {
                placement: 'topRight',
                message: e.message,
            } );
        }
    }
);

export const deleteCustomer = createAsyncThunk(
    'customers/delete',
    async ( data, actions ) => {
        try {
            actions.dispatch( setLoadingState( true ) )
            const response = await fetchingDataWithAxiosMiddleware(
                "DELETE",
                Connection.Customers( data.id ),
            );

            if ( response.data.message === "success" ) {
                notification.success( {
                    placement: 'topRight',
                    message: "Deleted successfully",
                } );
                return actions.dispatch( getCustomers() )
            }
        } catch ( e ) {
            actions.dispatch( setLoadingState( false ) )
            notification.error( {
                placement: 'topRight',
                message: e.message,
            } );
        }
    }
);
