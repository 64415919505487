import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Card, InputNumber } from 'antd';
import { setModalType } from "../../../Redux/Slices/modal";
import { editTeacher } from "../../../Redux/Slices/Education/Teachers/asyncThunks";
import { getPackages } from "../../../Redux/Slices/Education/Packages/asyncThunks";

const MyFormItemContext = React.createContext( [] );

function toArr( str ) {
    return Array.isArray( str ) ? str : [ str ];
}


const MyFormItem = ( { name, ...props } ) => {
    const prefixPath = React.useContext( MyFormItemContext );
    const concatName = name !== undefined ? [ ...prefixPath, ...toArr( name ) ] : undefined;
    const getRules = ( name = "" ) => {
        if ( name === "email" ) {
            return [
                {
                    required: true,
                    type: "email",
                    message: "The input is not valid E-mail!",
                },
            ]
        }
        if ( name === "phone" ) {
            return [
                {
                    required: true,
                    type: "number",
                    message: "This field cannot be left blank",
                },
            ]
        }
        return [ { required: true, message: `This field cannot be left blank` } ]
    }


    return <Form.Item rules={ getRules( concatName[ 0 ] ) } name={ concatName } { ...props } />;
};

export const EditTeacherData = () => {
    const dispatch = useDispatch()
    const teachers = useSelector( state => state.teachers.all )
    const packages = useSelector( state => state.packages.all )
    const transfer_id = useSelector( state => state.modal.transfer_id )
    const selected_teacher = teachers.filter( student => student.id === transfer_id )[ 0 ]
    const [ changedPackageID ] = useState( null )


    useEffect( () => {
        if ( !packages.length ) {
            dispatch( getPackages() )
        }
    }, [ dispatch, packages.length ] )


    const onFinish = ( value ) => {
        let data = {}
        for ( let prop in value ) {
            if ( prop === "phone" ) {
                if ( value[ "phone" ] !== parseInt( selected_teacher[ "phone" ] ) ) {
                    data[ "phone" ] = value[ "phone" ]
                }
            }
            if ( value[ prop ] !== selected_teacher[ prop ] && prop !== "phone" ) {
                data[ prop ] = value[ prop ]
            }
        }

        if ( Object.keys( data ).length !== 0 ) {
            data.id = transfer_id
            if ( changedPackageID ) {
                data.packageId = changedPackageID
            }
            dispatch( editTeacher( data ) )
            dispatch( setModalType( null ) )
        }
    };

    return <Card title={ `Edit teacher ID: ${ transfer_id }` }
                 style={ { width: 370, textAlign: "center", display: "flex", flexDirection: "column" } }>
        <Form
            name="form_item_path" layout="vertical" onFinish={ onFinish }
            initialValues={ {
                "name": selected_teacher.name || "",
                "lastName": selected_teacher.lastName,
                "email": selected_teacher.email,
                "phone": parseInt( selected_teacher.phone ),
                "position": selected_teacher.position,
            } }
        >
            <MyFormItem name="name" label="First Name">
                <Input/>
            </MyFormItem>
            <MyFormItem name="lastName" label="Last Name">
                <Input/>
            </MyFormItem>
            <MyFormItem name="email" label="Email">
                <Input/>
            </MyFormItem>
            <MyFormItem name="phone" label="Phone">
                <InputNumber
                    minLength={ 8 }
                    maxLength={ 9 }
                    style={ { width: "100%" } }
                />
            </MyFormItem>
            <MyFormItem name="position" label="Position">
                <Input/>
            </MyFormItem>

            {/*<Select*/ }
            {/*    labelInValue*/ }
            {/*    allowClear*/ }
            {/*    style={{*/ }
            {/*        position: "relative",*/ }
            {/*        margin: "15px 0",*/ }
            {/*        width: '100%',*/ }
            {/*    }}*/ }
            {/*    placeholder={packages.filter(item => item.id === selected_teacher.packageId)[0]?.name || "Packages"}*/ }
            {/*    onChange={handlePackageChange}*/ }
            {/*    options={packageOptions}*/ }
            {/*/>*/ }
            {/* 
            <MyFormItem name="packageId" label="Package">
                <Input disabled placeholder={

                }  />
            </MyFormItem> */ }

            <div style={ { width: "100%", display: "flex", justifyContent: "center" } }>
                <Button
                    type="default"
                    onClick={ () => dispatch( setModalType( null ) ) }
                    style={ { marginRight: "30px" } }
                >Cancel</Button>
                <Button type="primary" htmlType="submit">
                    Save
                </Button>
            </div>
        </Form>
    </Card>
}
