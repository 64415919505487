import i18n from "i18next"
import Beckend from "i18next-http-backend"
import LangageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"


i18n.use(Beckend).use(LangageDetector).use(initReactI18next).init({
    fallbackLng: "en",
    debug: true,
    detection: {
        order: ['queryString', 'cookie'],
        cache: ['cookie']
    },
    interpolation: {
        escapeValue: false
    }
})

export default i18n