import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Card, Space, Select, Radio } from 'antd';
import { setModalType } from "../../../Redux/Slices/modal";
import { useTranslation } from "react-i18next";
import { addExams, editExams } from "../../../Redux/Slices/Education/Exams/asyncThunk";

const { TextArea } = Input
const MyFormItemContext = React.createContext( [] );

function toArr( str ) {
    return Array.isArray( str ) ? str : [ str ];
}

const MyFormItemGroup = ( { prefix, children } ) => {
    const prefixPath = React.useContext( MyFormItemContext );
    const concatPath = React.useMemo( () => [ ...prefixPath, ...toArr( prefix ) ], [ prefixPath, prefix ] );
    return <MyFormItemContext.Provider value={ concatPath }>{ children }</MyFormItemContext.Provider>;
};
const MyFormItem = ( { name, ...props } ) => {
    const prefixPath = React.useContext( MyFormItemContext );
    const concatName = name !== undefined ? [ ...prefixPath, ...toArr( name ) ] : undefined;
    return <Form.Item name={ concatName } { ...props } />;
};


export const EditExamData = () => {
    // const { t } = useTranslation( "common" )
    //TODO check save button logic && form validations && updating data in languages mode
    const dispatch = useDispatch()
    const languages = useSelector( state => state.languages.data )
    const langId = useSelector( state => state.languages.selected )
    const selected_language = languages.filter( language => language.id === langId )[ 0 ]

    const exams = useSelector( state => state.exams.all )
    const transfer_id = useSelector( state => state.modal.transfer_id )
    const process_type = useSelector( state => state.modal.process_type )
    const processed_item_id = useSelector( state => state.modal.processed_item_id )
    const selected_exam = exams.filter( exam => exam.id === transfer_id )[ 0 ]
    const selected_question = selected_exam?.questions?.filter( question => question.id === processed_item_id )[ 0 ]


    const onFinish = ( value ) => {
        dispatch( editExams( {
            ...value,
            langId,
            examId: selected_exam.id,
            questionId: selected_question?.id,
            answerId: selected_question?.answers[ 0 ]?.id
        } ) )
    };


    return <Card
        style={ { position: "relative", width: 370, textAlign: "center", display: "flex", flexDirection: "column" } }
        title={ `EDIT Exam language -> ${ selected_language.name }` }
    >
        <Form
            initialValues={ process_type === "examInfo" ? {
                    [ "name" ]: selected_exam.name,
                    [ "level" ]: selected_exam.level,
                    [ "duration" ]: selected_exam.duration,
                    [ "duration_format" ]: selected_exam.duration_format,
                }
                : {
                    [ "title" ]: selected_question?.translations?.filter( translate => translate.langId === langId )[ 0 ]?.title,
                    [ "question" ]: selected_question?.translations?.filter( translate => translate.langId === langId )[ 0 ]?.question,
                    [ "correct_answer" ]: selected_question?.answers[ 0 ]?.correct_answer,
                    [ "answer_a" ]: selected_question?.answers[ 0 ]?.translations?.filter( translate => translate.langId === langId )[ 0 ]?.answer_a,
                    [ "answer_b" ]: selected_question?.answers[ 0 ]?.translations?.filter( translate => translate.langId === langId )[ 0 ]?.answer_b,
                    [ "answer_c" ]: selected_question?.answers[ 0 ]?.translations?.filter( translate => translate.langId === langId )[ 0 ]?.answer_c,
                    [ "answer_d" ]: selected_question?.answers[ 0 ]?.translations?.filter( translate => translate.langId === langId )[ 0 ]?.answer_d,
                } }
            name="form_item_path" layout="vertical" onFinish={ onFinish }>
            {
                process_type === "examInfo" ? <>
                    <MyFormItem name="name" label="Exam">
                        <Input placeholder="JavaScript"/>
                    </MyFormItem>
                    <MyFormItem name="level" label="Level">
                        <Input placeholder="Easy"/>
                    </MyFormItem>
                    <MyFormItem name="icon" label="Icon">
                        <Input placeholder="icon"/>
                    </MyFormItem>
                    <MyFormItem name="duration" label="Duration">
                        <Input placeholder="30"/>
                    </MyFormItem>
                    <MyFormItem name="duration_format" label="Format">
                        <Input placeholder="minutes"/>
                    </MyFormItem>
                </> : <></>
            }

            {
                process_type === "examQuestion" ? <>
                    <MyFormItem name="title" label="title">
                        <Input placeholder="Ex. Question 1"/>
                    </MyFormItem>
                    <MyFormItem name="question" label="Question">
                        <TextArea rows={ 3 } placeholder="Write your question here."/>
                    </MyFormItem>
                    <MyFormItem label="Correct Answer" name="correct_answer">
                        <Radio.Group>
                            <Radio.Button value="answer_a">A</Radio.Button>
                            <Radio.Button value="answer_b">B</Radio.Button>
                            <Radio.Button value="answer_c">C</Radio.Button>
                            <Radio.Button value="answer_d">D</Radio.Button>
                        </Radio.Group>
                    </MyFormItem>
                    <MyFormItem name="answer_a" label="Answer_a">
                        <Input placeholder="A) Your answer here"/>
                    </MyFormItem>
                    <MyFormItem name="answer_b" label="Answer_b">
                        <Input placeholder="B) Your answer here"/>
                    </MyFormItem>
                    <MyFormItem name="answer_c" label="Answer_c">
                        <Input placeholder="C) Your answer here"/>
                    </MyFormItem>
                    <MyFormItem name="answer_d" label="Answer_d">
                        <Input placeholder="D) Your answer here"/>
                    </MyFormItem>
                </> : <></>
            }

            <div style={ { width: "100%", display: "flex", justifyContent: "center" } }>
                <Button
                    type="default"
                    onClick={ () => dispatch( setModalType( null ) ) }
                    style={ { marginRight: "30px" } }
                >Cancel</Button>
                <Button type="primary" htmlType="submit">
                    Change
                </Button>
            </div>
        </Form>
    </Card>
}
