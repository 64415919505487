import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchingDataWithAxiosMiddleware } from "../../fetch";
import Connection from "../../../../Services/connections";
import { getTeachers } from "../Teachers/asyncThunks";
import { getLessons } from "./asyncThunks";
import { setLoadingState } from "../../loading";
import { notification } from "antd";


export const addSubLesson = createAsyncThunk(
    'lessons/addSubLesson',
    async ( data, actions ) => {
        try {
            const formData = new FormData()
            for ( let prop in data ) {
                if ( prop !== "language" ) {
                    formData.append( prop, data[ prop ] )
                }
            }
            const response = await fetchingDataWithAxiosMiddleware(
                "POST",
                Connection.EducationSubLessons( data.language ),
                formData
            );

            if ( response.data.message === "success" ) {
                notification.success( {
                    placement: 'topRight',
                    message: "Successfully added",
                } );
                return actions.dispatch( getLessons() )
            }
            return response.data
        } catch ( e ) {
            actions.dispatch( setLoadingState( false ) )
            notification.error( {
                placement: 'topRight',
                message: e.message,
            } );
        }
    }
);

export const editSubLesson = createAsyncThunk(
    'lessons/editSubLesson',
    async ( data, actions ) => {

        try {
            const formData = new FormData()
            for ( let prop in data ) {
                if ( prop !== "language" ) {
                    if ( data[ prop ] ) {
                        formData.append( prop, data[ prop ] )
                    }
                    if ( data[ prop ] === "-" ) {
                        formData.append( prop, "" )
                    }
                }
            }

            const response = await fetchingDataWithAxiosMiddleware(
                "PUT",
                Connection.EducationSubLessons( data.language ),
                formData
            );

            if ( response.data.message === "success" ) {
                notification.success( {
                    placement: 'topRight',
                    message: "Successfully edited",
                } );
                return actions.dispatch( getLessons() )
            }
            return response.data
        } catch ( e ) {
            actions.dispatch( setLoadingState( false ) )
            notification.error( {
                placement: 'topRight',
                message: e.message,
            } );
        }
    }
);


export const deleteSubLesson = createAsyncThunk(
    'lessons/deleteSubLesson',
    async ( data, actions ) => {

        try {
            const response = await fetchingDataWithAxiosMiddleware(
                "DELETE",
                Connection.EducationSubLessons( data.id ),
            );

            if ( response.data.message === "success" ) {
                notification.success( {
                    placement: 'topRight',
                    message: "Successfully deleted",
                } );
                return actions.dispatch( getLessons() )
            }
            return response.data
        } catch ( e ) {
            actions.dispatch( setLoadingState( false ) )
            notification.error( {
                placement: 'topRight',
                message: e.message,
            } );
        }
    }
);
