import { SortableContext, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { Table } from "antd";
import React from "react";
import { CSS } from "@dnd-kit/utilities";
import { AppstoreAddOutlined, MenuOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { setModalTransferData } from "../../Redux/Slices/modal";


const Row = ( { children, ...props } ) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        setActivatorNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable( {
        id: props[ 'data-row-key' ],
    } );
    const style = {
        ...props.style,
        transform: CSS.Transform.toString(
            transform && {
                ...transform,
                scaleY: 1,
            },
        ),
        transition,
        ...( isDragging
            ? {
                position: 'relative',
                zIndex: 9999,
            }
            : {} ),
    };
    return (
        <tr { ...props } ref={ setNodeRef } style={ style } { ...attributes }>
            { React.Children.map( children, ( child ) => {
                if ( child.key === 'sort' ) {
                    return React.cloneElement( child, {
                        children: (
                            <MenuOutlined
                                ref={ setActivatorNodeRef }
                                style={ {
                                    touchAction: 'none',
                                    cursor: 'move',
                                } }
                                { ...listeners }
                            />
                        ),
                    } );
                }
                return child;
            } ) }
        </tr>
    );
};

const rowSelection = {
    onChange: ( selectedRowKeys, selectedRows ) => {
        // console.log( `selectedRowKeys: ${ selectedRowKeys }`, 'selectedRows: ', selectedRows );
    },
    getCheckboxProps: ( record ) => ( {
        disabled: record.name === 'Disabled User',
        // Column configuration not to be checked
        name: record.name,
    } ),
};

export const TableBuilder = ( {
                                  tableData = [],
                                  columns = [],
                                  expandableName,
                                  expandableColumns = [],
                                  expandableAnswers = "",
                                  expandableAnswersColumns
                              } ) => {
    const dispatch = useDispatch()

    return <SortableContext
        items={ tableData.map( ( i ) => i.id ) }
        strategy={ verticalListSortingStrategy }
    >
        <Table
            components={ {
                body: {
                    row: Row,
                },
            } }
            expandable={ {
                expandedRowRender: ( record ) => {
                    return <>
                        <Table
                            rowKey="id"
                            columns={ expandableColumns }
                            dataSource={ record[ expandableName ] }
                            pagination={ false }
                            expandedRowRender={ ( record ) => {
                                return <Table
                                    rowKey="id"
                                    columns={ expandableAnswersColumns }
                                    dataSource={ record[ expandableAnswers ] }
                                    pagination={ false }
                                />
                            } }
                        />
                        <div style={ {
                            width: "100%",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        } }>
                            <AppstoreAddOutlined
                                onClick={ () => {
                                    dispatch( setModalTransferData( {
                                        modalType: "addExamQuestion",
                                        transfer_id: record.id,
                                        process_type: "addQuestion",
                                    } ) )
                                } }
                                style={ {
                                    fontSize: "25px", cursor: "pointer",
                                } }
                            />
                        </div>
                    </>;
                },
            } }
            rowSelection={ {
                type: "checkbox",
                ...rowSelection,
            } }
            rowKey="id"
            columns={ columns }
            dataSource={ tableData }
        />
    </SortableContext>
}