import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchingDataWithAxiosMiddleware } from "../../fetch";
import Connection from "../../../../Services/connections";
import { setLoadingState } from "../../loading";
import { notification } from "antd";

export const getTimeLines = createAsyncThunk(
    'timeLines/getAll',
    async ( _, actions ) => {
        try {
            actions.dispatch( setLoadingState( true ) )
            const response = await fetchingDataWithAxiosMiddleware(
                "GET",
                Connection.EducationTimeLines(),
            );
            actions.dispatch( setLoadingState( false ) )
            return response.data.timeLines
        } catch ( e ) {
            notification.success( {
                placement: 'topRight',
                message: e.message,
            } );
            actions.dispatch( setLoadingState( false ) )
        }
    }
);


export const addTimeLines = createAsyncThunk(
    'timeLines/add',
    async ( data, actions ) => {
        actions.dispatch( setLoadingState( true ) )
        try {
            const formData = new FormData()
            for ( let prop in data ) {
                formData.append( prop, data[ prop ] )
            }
            const response = await fetchingDataWithAxiosMiddleware(
                "POST",
                Connection.EducationTimeLines(),
                formData
            );

            if ( response.data.message === "success" ) {
                notification.success( {
                    placement: 'topRight',
                    message: "Successfully added",
                } );
                return actions.dispatch( getTimeLines() )
            }
        } catch ( e ) {
            notification.error( {
                placement: 'topRight',
                message: e.message,
            } );
        }
    }
);


export const editTimeLines = createAsyncThunk(
    'timeLines/edit',
    async ( data, actions ) => {
        actions.dispatch( setLoadingState( true ) )
        try {
            const formData = new FormData()
            for ( let prop in data ) {
                formData.append( prop, data[ prop ] )
            }
            const response = await fetchingDataWithAxiosMiddleware(
                "PUT",
                Connection.EducationTimeLines(),
                formData
            );

            if ( response.data.message === "success" ) {
                notification.success( {
                    placement: 'topRight',
                    message: "Successfully edited",
                } );
                return actions.dispatch( getTimeLines() )
            }
        } catch ( e ) {
            notification.error( {
                placement: 'topRight',
                message: e.message,
            } );
        }
    }
);


export const deleteTimeLines = createAsyncThunk(
    'timeLines/delete',
    async ( data, actions ) => {
        actions.dispatch( setLoadingState( true ) )
        try {
            const response = await fetchingDataWithAxiosMiddleware(
                "DELETE",
                Connection.EducationTimeLines( data.id ),
            );
            if ( response.data.message === "success" ) {
                notification.success( {
                    placement: 'topRight',
                    message: "Successfully added",
                } );
                return actions.dispatch( getTimeLines() )
            }
        } catch ( e ) {
            notification.error( {
                placement: 'topRight',
                message: e.message,
            } );
        }
    }
);
