import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, Card, Select } from 'antd';
import { setModalType } from "../../../Redux/Slices/modal";
import { classroomsOptions, durationOption, startEndOption, weekDaysOption } from "./utils";
import { addTimeLines } from "../../../Redux/Slices/Education/TimeLine/asyncThunks";
import { v4 as uuidV4 } from "uuid";
import { getTeachers } from "../../../Redux/Slices/Education/Teachers/asyncThunks";


export const AddTimeData = () => {
    const dispatch = useDispatch()
    const teachers = useSelector(state => state.teachers.all)
    const [timeLine, setTimeLine] = useState({
        weekDays: "",
        classroom: "",
        dayStartTime: "",
        dayFinishTime: "",
        duration: "",
    })


    useEffect(() => {
        dispatch(getTeachers())
    }, [dispatch])

    const teachersOption = teachers.filter(teacher => teacher?.TimeLines?.length < 2).map(teacher => {
        return {
            key: uuidV4(),
            label: `${teacher.name} ${teacher.lastName} || ${teacher.position}`,
            value: `${teacher.id}`
        }
    })



    const handleChangeWeekDays = (value) => {
        setTimeLine(prev => {
            return {
                ...prev,
                weekDays: value.toString(","),
            }
        })
    };

    const handleChangeStartEndTime = (data) => {
        const date = data.value.split(",")
        setTimeLine(prev => {
            return {
                ...prev,
                dayStartTime: date[0],
                dayFinishTime: date[1],
            }
        })
    };

    const handlerChangeTeacher = (data) => {
        setTimeLine(prev => {
            return {
                ...prev,
                teacherId: data.value
            }
        })
    };

    const handleChangeDuration = (data) => {
        setTimeLine(prev => {
            return {
                ...prev,
                duration: data.value
            }
        })
    };


    const handleChangeClassrooms = (data) => {
        setTimeLine(prev => {
            return {
                ...prev,
                classroom: data.value
            }
        })
    };

    const onFinish = () => {
        dispatch(addTimeLines(timeLine))
        dispatch(setModalType(null))
    };

    return <Card title={"Add Time"}
        style={{ width: 370, textAlign: "center", display: "flex", flexDirection: "column" }}>
        <Form name="form_item_path" layout="vertical" onFinish={onFinish}>

            <Select
                mode="tags"
                allowClear
                style={{
                    position: "relative",
                    width: '100%',
                }}
                placeholder="Week Days"
                onChange={handleChangeWeekDays}
                options={weekDaysOption}
            />

            <Select
                labelInValue
                allowClear
                style={{
                    position: "relative",
                    margin: "25px 0",
                    width: '100%',
                }}
                placeholder="Start & End"
                onChange={handleChangeStartEndTime}
                options={startEndOption}
            />

            <Select
                labelInValue
                allowClear
                style={{
                    position: "relative",
                    margin: "0 0 25px 0",
                    width: '100%',
                }}
                placeholder="Duration"
                onChange={handleChangeDuration}
                options={durationOption}
            />
            <Select
                labelInValue
                allowClear
                style={{
                    position: "relative",
                    margin: "0 0 25px 0",
                    width: '100%',
                }}
                placeholder="Classrooms"
                onChange={handleChangeClassrooms}
                options={classroomsOptions}
            />
            <Select
                labelInValue
                allowClear
                style={{
                    position: "relative",
                    margin: "0 0 25px 0",
                    width: '100%',
                }}
                placeholder="Teachers"
                onChange={handlerChangeTeacher}
                options={teachersOption}
            />
            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <Button
                    type="default"
                    onClick={() => dispatch(setModalType(null))}
                    style={{ marginRight: "30px" }}
                >Cancel</Button>
                <Button disabled={
                    !(timeLine.weekDays &&
                        timeLine.classroom &&
                        timeLine.dayStartTime &&
                        timeLine.dayFinishTime &&
                        timeLine.duration)
                } type="primary" htmlType="submit">
                    Add
                </Button>
            </div>
        </Form>
    </Card>
}
