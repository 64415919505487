import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Card, Space, Select } from 'antd';
import { setModalType } from "../../../Redux/Slices/modal";
import { addContent } from "../../../Redux/Slices/Content/asyncThunk";

const { TextArea } = Input
const MyFormItemContext = React.createContext( [] );

function toArr( str ) {
    return Array.isArray( str ) ? str : [ str ];
}

const MyFormItemGroup = ( { prefix, children } ) => {
    const prefixPath = React.useContext( MyFormItemContext );
    const concatPath = React.useMemo( () => [ ...prefixPath, ...toArr( prefix ) ], [ prefixPath, prefix ] );
    return <MyFormItemContext.Provider value={ concatPath }>{ children }</MyFormItemContext.Provider>;
};
const MyFormItem = ( { name, ...props } ) => {
    const prefixPath = React.useContext( MyFormItemContext );
    const concatName = name !== undefined ? [ ...prefixPath, ...toArr( name ) ] : undefined;
    return <Form.Item name={ concatName } { ...props } />;
};


export const AddContentData = () => {
    const dispatch = useDispatch()
    const selected = useSelector( state => state.languages.selected )

    const onFinish = ( value ) => {
        dispatch( addContent( { ...value, langId: selected } ) )
        dispatch( setModalType( null ) )
    };


    return <Card
        style={ { position: "relative", width: 370, textAlign: "center", display: "flex", flexDirection: "column" } }
        title={ `New content` }
    >
        <Form
            name="form_item_path" layout="vertical" onFinish={ onFinish }>
            <MyFormItem name="name" label="Content name">
                <Input/>
            </MyFormItem>
            <MyFormItem name="title" label="Title">
                <Input/>
            </MyFormItem>
            <MyFormItem name="previewDesc" label="Short text">
                <TextArea rows={ 5 }/>
            </MyFormItem>
            <MyFormItem name="description" label="Description">
                <TextArea rows={ 10 }/>
            </MyFormItem>

            <div style={ { width: "100%", display: "flex", justifyContent: "center" } }>
                <Button
                    type="default"
                    onClick={ () => dispatch( setModalType( null ) ) }
                    style={ { marginRight: "30px" } }
                >Cancel</Button>
                <Button type="primary" htmlType="submit">
                    Add
                </Button>
            </div>
        </Form>
    </Card>
}
