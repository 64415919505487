import { createSlice } from '@reduxjs/toolkit'
import { generateQr, getQrs, printQr } from "./asyncThunks";


export const qr = createSlice( {
    name: 'qr',
    initialState: {
        qrs: [],
        is_modal_open: false,
    },
    reducers: {
        changeModalState: ( state, action ) => {
            state.is_modal_open = action.payload
        },
        setQrsState: ( state, action ) => {
            state.qrs = action.payload
        }
    },
    extraReducers: ( builder ) => {
        builder
            .addCase( getQrs.fulfilled, ( state, action ) => {
                state.qrs = action.payload;
            } );
        builder
            .addCase( printQr.fulfilled, ( state, action ) => {
                state.qrs = action.payload;
            } );
    }
} )

export const { changeModalState } = qr.actions
export default qr.reducer