import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Card, notification, InputNumber } from 'antd';
import { setModalType } from "../../../Redux/Slices/modal";
import { addTeacher } from "../../../Redux/Slices/Education/Teachers/asyncThunks";

const MyFormItemContext = React.createContext([]);

function toArr(str) {
    return Array.isArray(str) ? str : [str];
}

const MyFormItemGroup = ({ prefix, children }) => {
    const prefixPath = React.useContext(MyFormItemContext);
    const concatPath = React.useMemo(() => [...prefixPath, ...toArr(prefix)], [prefixPath, prefix]);
    return <MyFormItemContext.Provider value={concatPath}>{children}</MyFormItemContext.Provider>;
};
const MyFormItem = ({ name, ...props }) => {
    const prefixPath = React.useContext(MyFormItemContext);
    const concatName = name !== undefined ? [...prefixPath, ...toArr(name)] : undefined;
    const getRules = (name = "") => {
        if (name === "email") {
            return [
                {
                    required: true,
                    type: "email",
                    message: "The input is not valid E-mail!",
                },
            ]
        }
        if (name === "phone") {
            return [
                {
                    required: true,
                    type: "number",
                    message: "This field cannot be left blank",
                },
            ]
        }
        return [{ required: true, message: `This field cannot be left blank` }]
    }


    return <Form.Item rules={getRules(concatName[0])} name={concatName} {...props} />;
};



export const AddTeacherData = () => {
    const dispatch = useDispatch()

    const onFinish = (value) => {
        dispatch( addTeacher( value ) )
        dispatch(setModalType(null))
    };

    return <Card title={"Add Teacher"}
        style={{ width: 370, textAlign: "center", display: "flex", flexDirection: "column" }}>
        <Form name="form_item_path" layout="vertical" onFinish={onFinish}>
            <MyFormItem name="name" label="First Name">
                <Input />
            </MyFormItem>
            <MyFormItem name="lastName" label="Last Name">
                <Input />
            </MyFormItem>
            <MyFormItem name="email" label="Email">
                <Input />
            </MyFormItem>
            <MyFormItem name="phone" label="Phone">
                <InputNumber 
                minLength={8}
                maxLength={9}
                    style={{width: "100%"}} 
                />
            </MyFormItem>
            <MyFormItem name="position" label="Position">
                <Input />
            </MyFormItem>

            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <Button
                    type="default"
                    onClick={() => dispatch(setModalType(null))}
                    style={{ marginRight: "30px" }}
                >Cancel</Button>
                <Button type="primary" htmlType="submit">
                    Add
                </Button>
            </div>
        </Form>
    </Card>
}
