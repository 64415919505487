import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Card, Select, Upload, InputNumber, notification } from 'antd';
import { setModalType } from "../../../Redux/Slices/modal";
import { v4 as uuidV4 } from "uuid"
import { getLessons } from "../../../Redux/Slices/Education/Lessons/asyncThunks";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { addPackageExemplar } from "../../../Redux/Slices/ContentPackages/asyncThunk";

const { TextArea } = Input
const MyFormItemContext = React.createContext( [] );

function toArr( str ) {
    return Array.isArray( str ) ? str : [ str ];
}

const MyFormItem = ( { name, ...props } ) => {
    const prefixPath = React.useContext( MyFormItemContext );
    const concatName = name !== undefined ? [ ...prefixPath, ...toArr( name ) ] : undefined;

    const getRules = ( name = "" ) => {
        if ( name === "photo" ) {
            return [
                {
                    required: true,
                    // type: "string",
                    message: "You must upload a photo to create a Package",
                },
            ]
        }

        if ( name === "duration" ) {
            return [
                {
                    required: true,
                    type: "number",
                },
            ]
        }
        return [ { required: true, message: `This field cannot be left blank` } ]
    }

    return <Form.Item rules={ getRules( concatName[ 0 ] ) } name={ concatName } { ...props } />;
};


const getBase64 = ( img, callback ) => {
    const reader = new FileReader();
    reader.addEventListener( 'load', () => callback( reader.result ) );
    reader.readAsDataURL( img );
};


const beforeUpload = ( file ) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if ( !isJpgOrPng ) {
        notification.error( {
            placement: 'topRight',
            message: "Image size must be 1MB or less.",
        } );
    }
    const isLt1M = file.size / 1024 / 1024 < 1; // Check if file size is less than 1MB
    if ( !isLt1M ) {
        notification.error( {
            placement: 'topRight',
            message: 'Image size must be 1MB or less.',
        } );
    }
    return isJpgOrPng && isLt1M;
};


export const AddContentPackage = () => {
    const dispatch = useDispatch()
    const lessons = useSelector( state => state.lessons.all )

    const [ loading, setLoading ] = useState( false );
    const [ uploadedImage, setUploadedImage ] = useState( { url: "", file: null } );

    const lessonsOptions = lessons?.map( lesson => {
        return {
            key: uuidV4(),
            label: lesson.name,
            value: `${ lesson.id }`,
        }
    } )

    useEffect( () => {
        dispatch( getLessons() )
    }, [ dispatch ] )

    const onFinish = ( value ) => {
        let data = {
            the: "Packages",
            is_exemplar: true,
            in_live: false,
        }

        for ( let prop in value ) {
            if ( value[ prop ] ) {
                if ( prop === "photo" ) {
                    data[ prop ] = value[ prop ].file.originFileObj
                } else {
                    data[ prop ] = value[ prop ]
                }
            }
        }
        dispatch( addPackageExemplar( data ) )
        dispatch( setModalType( null ) )
    };


    const handleChange = ( info ) => {
        if ( info.file ) {
            // Get this url from response in real world.
            getBase64( info.file.originFileObj, ( url ) => {
                setLoading( false );
                setUploadedImage( { url, file: info.file.originFileObj } );
            } );
        }
    };

    const uploadButton = (
        <button style={ { border: 0, background: 'none' } } type="button">
            { loading ? <LoadingOutlined/> : <PlusOutlined/> }
            <div style={ { marginTop: 8 } }>Upload</div>
        </button>
    );

    return <Card
        style={ { position: "relative", width: 500, textAlign: "center", display: "flex", flexDirection: "column" } }
        title={ `New Content Package` }
    >
        <Form
            name="form_item_path" layout="vertical" onFinish={ onFinish }>

            <MyFormItem name="photo" label="Photo">
                <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={ false }
                    // TODO request post error here
                    // action="any url here"
                    onChange={ handleChange }
                    style={ { width: '300px' } }
                    beforeUpload={ beforeUpload }
                >
                    { uploadedImage.url ?
                        <img src={ uploadedImage.url } alt="avatar" style={ { width: '100%' } }/> : uploadButton }
                </Upload>
            </MyFormItem>

            <MyFormItem name="name" label="Name">
                <Input placeholder="Name"/>
            </MyFormItem>

            <div style={ { width: "100%", display: "flex", justifyContent: "space-between" } }>
                <MyFormItem name="price" label="Price">
                    <InputNumber style={ { width: 200 } } max={ 300000 } maxLength={ 6 } placeholder="Price"/>
                </MyFormItem>
                <MyFormItem name="pricePrefix" label="Prefix">
                    <Select
                        // defaultValue="lucy"
                        style={ { width: 200 } }
                        placeholder="AMD"
                        onChange={ handleChange }
                        options={ [
                            { value: 'AMD', label: 'AMD' },
                            { value: 'USD', label: 'USD' },
                            { value: 'RUB', label: 'RUB', disabled: true }
                        ] }
                    />
                </MyFormItem>
            </div>

            <div style={ { width: "100%", display: "flex", justifyContent: "space-between" } }>
                <MyFormItem name="duration" label="Duration">
                    <InputNumber style={ { width: 200 } } max={ 29 } maxLength={ 2 } placeholder="Duration"/>
                </MyFormItem>
                <MyFormItem name="durationPrefix" label="Prefix">
                    <Select
                        style={ { width: 200 } }
                        placeholder="DAY"
                        onChange={ handleChange }
                        options={ [
                            { value: 'DAY', label: 'DAY' },
                            { value: 'Month', label: 'Month' },
                            { value: 'Year', label: 'Year', disabled: true }
                        ] }
                    />
                </MyFormItem>
            </div>

            <MyFormItem name="description" label="Description">
                <TextArea placeholder="Text about of package" rows={ 5 }/>
            </MyFormItem>

            <MyFormItem name="lessons" label="Lessons">
                <Select
                    mode="tags"
                    style={ {
                        position: "relative",
                        width: '100%',
                        margin: "10px 0 20px 0"
                    } }
                    placeholder="Lessons"
                    options={ lessonsOptions }
                />
            </MyFormItem>

            <div style={ { width: "100%", display: "flex", justifyContent: "center" } }>
                <Button
                    type="default"
                    onClick={ () => dispatch( setModalType( null ) ) }
                    style={ { marginRight: "30px" } }
                >
                    Cancel
                </Button>
                <Button type="primary" htmlType="submit">
                    Add
                </Button>
            </div>
        </Form>
    </Card>
}


export default AddContentPackage