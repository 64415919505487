import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Card, InputNumber, Select, Upload, notification } from 'antd';
import { setModalType } from "../../../Redux/Slices/modal";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import Connection from "../../../Services/connections"
import { editLesson } from "../../../Redux/Slices/Education/Lessons/asyncThunks";


const MyFormItemContext = React.createContext( [] );
const { TextArea } = Input


function toArr( str ) {
    return Array.isArray( str ) ? str : [ str ];
}

const MyFormItem = ( { name, ...props } ) => {
    const prefixPath = React.useContext( MyFormItemContext );
    const concatName = name !== undefined ? [ ...prefixPath, ...toArr( name ) ] : undefined;
    return <Form.Item name={ concatName } { ...props } />;
};


const getBase64 = ( img, callback ) => {
    const reader = new FileReader();
    reader.addEventListener( 'load', () => callback( reader.result ) );
    reader.readAsDataURL( img );
};


const beforeUpload = ( file ) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if ( !isJpgOrPng ) {
        notification.error( {
            placement: 'topRight',
            message: "Image size must be 1MB or less.",
        } );
    }
    const isLt1M = file.size / 1024 / 1024 < 1;
    if ( !isLt1M ) {
        notification.error( {
            placement: 'topRight',
            message: 'Image size must be 1MB or less.',
        } );
    }
    return isJpgOrPng && isLt1M;
};

export const EditLesson = () => {
    const dispatch = useDispatch()
    const selected = useSelector( state => state.languages.selected )
    const transfer_id = useSelector( state => state.modal.transfer_id )
    const lessons = useSelector( state => state.lessons.all )
    const selectedLesson = lessons.filter( lesson => lesson.id === transfer_id )[ 0 ]
    const [ loading, setLoading ] = useState( false );
    const [ uploadedImage, setUploadedImage ] = useState( { url: "", file: null } );

    const onFinish = ( value ) => {

        let data = {
            id: transfer_id
        }

        for ( let prop in value ) {
            if ( value[ prop ] && value[ prop ] !== 0 ) {
                if (
                    value[ prop ] && value[ prop ] !== selectedLesson[ prop ]
                    && prop !== "icon" && prop !== "title" && prop !== "description" && prop !== "price"
                ) {
                    data[ prop ] = value[ prop ]
                }
                if ( prop === "icon" ) {
                    data.the = "Lessons"
                    data.photo = value[ prop ]?.file?.originFileObj
                }
                if (
                    ( prop === "title" || prop === "description" )
                    && value[ prop ] !== selectedLesson[ prop ][ selected ]
                ) {
                    data[ prop ] = value[ prop ]
                }
                if (
                    prop === "price"
                    && value.price !== selectedLesson?.price[ selected ]?.substring(
                        0, selectedLesson?.price[ selected ].indexOf( selected === "en" ? "USD" : "AMD" )
                    )
                ) {
                    data[ prop ] = `${ value[ prop ] } ${ selected === "en" ? "USD" : "AMD" }`
                }
            }
        }

        data.language = selected

        dispatch( editLesson( data ) )
        dispatch( setModalType( null ) )
    };

    const handleChange = ( info ) => {
        if ( info.file ) {
            // Get this url from response in real world.
            getBase64( info.file.originFileObj, ( url ) => {
                setLoading( false );
                setUploadedImage( { url, file: info.file.originFileObj } );
            } );
        }
    };

    const uploadButton = (
        <button style={ { border: 0, background: 'none' } } type="button">
            { loading ? <LoadingOutlined/> : <PlusOutlined/> }
            <div style={ { marginTop: 8 } }>Upload</div>
        </button>
    )


    return <Card
        style={ { position: "relative", width: 370, textAlign: "center", display: "flex", flexDirection: "column" } }
        title={ `Edit: ${ selectedLesson.name } Lesson || ${ selected.toUpperCase() } ` }
    >
        <Form
            name="form_item_path" layout="vertical" onFinish={ onFinish }
            initialValues={ {
                [ "name" ]: selectedLesson?.name,
                [ "title" ]: selectedLesson?.title[ selected ],
                [ "description" ]: selectedLesson?.description[ selected ],
                [ "duration" ]: selectedLesson?.duration,
                [ "durationPrefix" ]: selectedLesson?.durationPrefix,
                [ "price" ]: selectedLesson?.price[ selected ]?.substring(
                    0, selectedLesson?.price[ selected ].indexOf( selected === "en" ? "USD" : "AMD" )
                ) || 0,
            } }
        >

            <MyFormItem name="name" label="Lesson">
                <Input placeholder="Lesson name"/>
            </MyFormItem>

            <MyFormItem name="title" label="Title">
                <Input/>
            </MyFormItem>

            <MyFormItem name="description" label="Description">
                <TextArea rows={ 3 } placeholder="Lesson description" required/>
            </MyFormItem>

            <MyFormItem name="icon" label="Icon">
                <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={ false }
                    // TODO request post error here
                    // action="any url here"
                    onChange={ handleChange }
                    style={ { width: '300px' } }
                    beforeUpload={ beforeUpload }
                >
                    { uploadedImage.url || Connection.GetImage( selectedLesson.icon ) ?
                        <img src={ uploadedImage.url || Connection.GetImage( selectedLesson.icon ) } alt="avatar"
                             style={ { width: '100%' } }/> : uploadButton }
                </Upload>
            </MyFormItem>

            <div style={ { width: "100%", display: "flex", justifyContent: "Space-between" } }>
                <MyFormItem name="duration" label="Duration">
                    <InputNumber
                        max={ 30 }
                        maxLength={ 2 }
                        style={ { width: "100%" } }
                        placeholder="1"
                        required
                    />
                </MyFormItem>
                <MyFormItem name="durationPrefix" label="Prefix">
                    <Select
                        // defaultValue="lucy"
                        placeholder="1 Month"
                        // onChange={ handleChange }
                        style={ {
                            maxWidth: "140px"
                        } }
                        options={ [
                            { value: 'd', label: 'Days' },
                            { value: 'w', label: 'Week' },
                            { value: 'm', label: 'Month' },
                            { value: 'y', label: 'Year' },
                        ] }
                        required
                    />
                </MyFormItem>
            </div>


            <div style={ { width: "100%", display: "flex", justifyContent: "Space-between" } }>
                <MyFormItem name="price" label="Price">
                    <InputNumber style={ { width: "100%" } } placeholder="Lesson price" required/>
                </MyFormItem>
                <MyFormItem name="" label=" ">
                    <Select
                        disabled
                        style={ {
                            position: "relative",
                            // margin: "25px 0",
                            width: '80px',
                        } }
                        placeholder={ selected === "en" ? "USD" : "AMD" }
                    />
                </MyFormItem>
            </div>

            <div style={ { width: "100%", display: "flex", justifyContent: "center" } }>
                <Button
                    type="default"
                    onClick={ () => dispatch( setModalType( null ) ) }
                    style={ { marginRight: "30px" } }
                >Cancel</Button>
                <Button type="primary" htmlType="submit">
                    Edit
                </Button>
            </div>
        </Form>
    </Card>
}