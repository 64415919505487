import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Card, Space, Select, Checkbox, Upload, notification } from 'antd';
import { setModalType } from "../../../Redux/Slices/modal";
import {
    addContent,
    addDescriptionContent,
    addMoreItemContent,
    editDescriptionContent
} from "../../../Redux/Slices/Content/asyncThunk";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
const { TextArea } = Input

const MyFormItemContext = React.createContext( [] );

function toArr( str ) {
    return Array.isArray( str ) ? str : [ str ];
}

const MyFormItem = ( { name, ...props } ) => {
    const prefixPath = React.useContext( MyFormItemContext );
    const concatName = name !== undefined ? [ ...prefixPath, ...toArr( name ) ] : undefined;
    const getRules = () => {
        return [ { required: true, message: `This field cannot be left blank` } ]
    }
    return <Form.Item rules={ getRules( concatName[ 0 ] ) } name={ concatName } { ...props } />;
};


const getBase64 = ( img, callback ) => {
    const reader = new FileReader();
    reader.addEventListener( 'load', () => callback( reader.result ) );
    reader.readAsDataURL( img );
};

const beforeUpload = ( file ) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if ( !isJpgOrPng ) {
        notification.error( {
            placement: 'topRight',
            message: "You can only upload JPG/PNG file!",
        } );
    }
    const isLt1M = file.size / 1024 / 1024 < 1; // Check if file size is less than 1MB
    if ( !isLt1M ) {
        notification.error( {
            placement: 'topRight',
            message: 'Image size must be 1MB or less.',
        } );
    }
    return isJpgOrPng && isLt1M;
};


export const AddContentMoreItemData = () => {
    const dispatch = useDispatch()
    const page_id = useSelector( state => state.modal.transfer_id )
    const selected = useSelector( state => state.languages.selected )

    const [ uploadedImage, setUploadedImage ] = useState( { url: "", file: null } );
    const [ loading, setLoading ] = useState( false );


    const onFinish = ( value ) => {
        const data = {
            the: "ContentMoreItems",
            can_show: false
        }
        for ( let prop in value ) {
            if ( value[ prop ] ) {
                if ( prop === "photo" ) {
                    data[ prop ] = value[ prop ].file.originFileObj
                } else {
                    data[ prop ] = value[ prop ]
                }
            }
        }
        if ( Object.keys( data ).length !== 0 && page_id ) {
            dispatch( addMoreItemContent( { data, page_id, language: selected } ) )
            dispatch( setModalType( null ) )
        }
    };


    const handleChange = ( info ) => {
        if ( info.file ) {
            // Get this url from response in real world.
            getBase64( info.file.originFileObj, ( url ) => {
                setLoading( false );
                setUploadedImage( { url, file: info.file.originFileObj } );
            } );
        }
    };

    const uploadButton = (
        <button style={ { border: 0, background: 'none' } } type="button">
            { loading ? <LoadingOutlined/> : <PlusOutlined/> }
            <div style={ { marginTop: 8 } }>Upload</div>
        </button>
    );


    return <Card
        style={ { position: "relative", width: 370, textAlign: "center", display: "flex", flexDirection: "column" } }
        title={ `New content more item: ${ selected.toUpperCase() }` }
    >
        <Form
            name="form_item_path" layout="vertical" onFinish={ onFinish }>
            <MyFormItem name="photo" label="Photo">
                <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={ false }
                    accept=".png"
                    // TODO request post error here
                    // action="any url here"
                    onChange={ handleChange }
                    style={ { width: '300px' } }
                    beforeUpload={ beforeUpload }
                >
                    { uploadedImage.url ?
                        <img src={ uploadedImage.url } alt="avatar" style={ { width: '100%' } }/>
                        : uploadButton }
                </Upload>
            </MyFormItem>
            <MyFormItem name="title" label="Title">
                <TextArea rows={3} placeholder="Title"/>
            </MyFormItem>
            <MyFormItem name="text" label="Text">
                <TextArea rows={6} placeholder="Description"/>
            </MyFormItem>

            <div style={ { width: "100%", display: "flex", justifyContent: "center" } }>
                <Button
                    type="default"
                    onClick={ () => dispatch( setModalType( null ) ) }
                    style={ { marginRight: "30px" } }
                >Cancel</Button>
                <Button type="primary" htmlType="submit">
                    Add
                </Button>
            </div>
        </Form>
    </Card>
}
