import { createSlice } from '@reduxjs/toolkit'
import { getPackagesExemplars } from "./asyncThunk";


export const contentPackages = createSlice( {
    name: 'contentPackages',
    initialState: {
        all: []
    },
    reducers: {},
    extraReducers: ( builder ) => {
        builder
            .addCase( getPackagesExemplars.fulfilled, ( state, action ) => {
                state.all = action.payload;
            } );
    }
} )

// export const {} = contentPackages.actions
export default contentPackages.reducer