import { createSlice } from '@reduxjs/toolkit'
import { getGroups } from "./asyncThunk";


export const groups = createSlice( {
    name: 'groups',
    initialState: {
        all: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getGroups.fulfilled, (state, action) => {
            state.all = action.payload
        })
    }
} )

export const {} = groups.actions
export default groups.reducer