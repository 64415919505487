import { createSlice } from '@reduxjs/toolkit'
import { getPackages } from "./asyncThunks";


export const packages = createSlice( {
    name: 'packages',
    initialState: {
        all: [],
    },
    reducers: {},
    extraReducers: ( builder ) => {
        builder
            .addCase( getPackages.fulfilled, ( state, action ) => {
                state.all = action.payload;
            } );
    }
} )

export const { } = packages.actions
export default packages.reducer