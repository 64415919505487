import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, Card, Select } from 'antd';
import { setModalType } from "../../../Redux/Slices/modal";
import { classroomsOptions, durationOption, startEndOption, weekDaysOption } from "./utils";
import { editTimeLines } from "../../../Redux/Slices/Education/TimeLine/asyncThunks";
import { v4 as uuidV4 } from "uuid";
import { getTeachers } from "../../../Redux/Slices/Education/Teachers/asyncThunks";


export const EditTimeData = () => {
    const dispatch = useDispatch()
    const transfer_id = useSelector(state => state.modal.transfer_id)
    const times = useSelector(state => state.timeLines.all)
    const teachers = useSelector(state => state.teachers.all)

    const [timeLine, setTimeLine] = useState({
        weekDays: "",
        classroom: "",
        dayStartTime: "",
        dayFinishTime: "",
        duration: "",
    })

    useEffect(() => {
        dispatch(getTeachers())
    }, [dispatch])

    const teachersOption = teachers.filter(teacher => !teacher?.timeLineId).map(teacher => {
        return {
            key: uuidV4(),
            label: `${teacher.name} ${teacher.lastName} || ${teacher.position}`,
            value: `${teacher.id}`
        }
    })



    const selectedTimeLine = times.filter(time => time.id === transfer_id)[0]


    const handleChangeWeekDays = (value) => {
        setTimeLine(prev => {
            return {
                ...prev,
                weekDays: value?.toString(","),
            }
        })
    };

    const handleChangeStartEndTime = (data) => {
        const date = data?.value?.split(",")
        setTimeLine(prev => {
            return {
                ...prev,
                dayStartTime: `${date[0]}`,
                dayFinishTime: `${date[1]}`,
            }
        })
    };

    const handleChangeDuration = (data) => {
        setTimeLine(prev => {
            return {
                ...prev,
                duration: data?.value
            }
        })
    };

    const handlerChangeTeacher = (data) => {
        setTimeLine(prev => {
            return {
                ...prev,
               teacherId: parseInt(data?.value)
            }
        })
    };


    const handleChangeClassrooms = (data) => {
        setTimeLine(prev => {
            return {
                ...prev,
                classroom: data?.value
            }
        })
    };

    const onFinish = () => {
        let data = {}
        for (let prop in timeLine) {
            if (timeLine[prop]) {
                data[prop] = timeLine[prop]
            }
        }
        if (Object.keys(data).length !== 0 || data.teacherId) {
            data.id = transfer_id
            dispatch(editTimeLines(data))
            dispatch(setModalType(null))
        }
    };


    return <Card title={"Edit Time"}
        style={{ width: 370, textAlign: "center", display: "flex", flexDirection: "column" }}
    >
        <Form name="form_item_path" layout="vertical" onFinish={onFinish}>

            <Select
                mode="tags"
                allowClear
                style={{
                    position: "relative",
                    width: '100%',
                }}
                placeholder="Week Days"
                defaultValue={selectedTimeLine?.weekDays?.split(",")}
                onChange={handleChangeWeekDays}
                options={weekDaysOption}
            />

            <Select
                labelInValue
                allowClear
                style={{
                    position: "relative",
                    margin: "25px 0",
                    width: '100%',
                }}
                placeholder="Start & End"
                defaultValue={{
                    label: `${selectedTimeLine.dayStartTime} && ${selectedTimeLine.dayFinishTime}`,
                    value: `${selectedTimeLine.dayStartTime},${selectedTimeLine.dayFinishTime}`
                }}
                onChange={handleChangeStartEndTime}
                options={startEndOption}
            />

            <Select
                labelInValue
                allowClear
                style={{
                    position: "relative",
                    margin: "0 0 25px 0",
                    width: '100%',
                }}
                placeholder="Duration"
                defaultValue={{
                    label: selectedTimeLine.duration,
                    value: selectedTimeLine.duration
                }}
                onChange={handleChangeDuration}
                options={durationOption}
            />
            <Select
                labelInValue
                allowClear
                style={{
                    position: "relative",
                    margin: "0 0 25px 0",
                    width: '100%',
                }}
                placeholder="Classrooms"
                defaultValue={{
                    label: selectedTimeLine.classroom,
                    value: selectedTimeLine.classroom
                }}
                onChange={handleChangeClassrooms}
                options={classroomsOptions}
            />
            <Select
                labelInValue
                allowClear
                style={{
                    position: "relative",
                    margin: "0 0 25px 0",
                    width: '100%',
                }}
                placeholder={`${teachers.filter(teacher => teacher.id === selectedTimeLine.teacherId)[0]?.name
                    } ${teachers.filter(teacher => teacher.id === selectedTimeLine.teacherId)[0]?.lastName
                    } ${teachers.filter(teacher => teacher.id === selectedTimeLine.teacherId)[0]?.position
                    }`}
                onChange={handlerChangeTeacher}
                options={teachersOption}
            />
            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <Button
                    type="default"
                    onClick={() => dispatch(setModalType(null))}
                    style={{ marginRight: "30px" }}
                >Cancel</Button>
                <Button type="primary" htmlType="submit">
                    Edit
                </Button>
            </div>
        </Form>
    </Card>
}
