import { createSlice } from '@reduxjs/toolkit'


export const modal = createSlice( {
    name: 'modal',
    initialState: {
        type: null,
        transfer_id: null,
        process_type: "",
        processed_item_id: null
    },
    reducers: {
        setModalType: ( state, action ) => {
            if ( action.payload === null ) {
                state.transfer_id = null
                state.process_type = ""
                state.processed_item_id = null
            }
            state.type = action.payload
        },
        setModalTransferData: ( state, action ) => {
            state.type = action.payload.modalType
            state.transfer_id = action.payload.transfer_id
            state.process_type = action.payload.process_type
            state.processed_item_id = action.payload.processed_item_id
        }
    }
} )

export const { setModalType, setModalTransferData } = modal.actions
export default modal.reducer