import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchingDataWithAxiosMiddleware } from "./fetch";
import Connections from "../../Services/connections"
import { setLoadingState } from "./loading";
import { notification } from "antd";


export const getLanguages = createAsyncThunk(
    "languages/getAll",
    async ( _, actions ) => {
        try {
            actions.dispatch( setLoadingState( true ) )
            const response = await fetchingDataWithAxiosMiddleware(
                "GET",
                Connections.GetLanguages()
            )
            actions.dispatch( setLoadingState( false ) )
            return response.data.languages
        } catch ( e ) {
            actions.dispatch( setLoadingState( false ) )
            notification.error( {
                placement: 'topRight',
                message: e.message,
            } );
        }

    }
)


export const languages = createSlice( {
    name: "languages",
    initialState: {
        selected: "en",
        data: []
    },
    reducers: {
        selectLanguage: ( state, action ) => {
            state.selected = action.payload
        }
    },
    extraReducers: builder => {
        builder.addCase( getLanguages.fulfilled, ( state, action ) => {
            state.data = action.payload
        } )
    }
} )

export const { selectLanguage } = languages.actions
export default languages.reducer