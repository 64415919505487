import { createSlice } from '@reduxjs/toolkit'
import { getContent } from "./asyncThunk";


export const content = createSlice( {
    name: 'content',
    initialState: {
        all: [
            {
                id: 1,
                name: "Greeting",
                type: "firstPage",
                icon: "",
                translations: [
                    {
                            lngId: 1,
                            contentId: 1,
                            title: "EN: Genie Web",
                            previewDesc: "EN: previewDesc: Any text here",
                            description:  `EN: I’m not sure I completely understand your question. Do you mean you wish each character to be this size? If you made the text in Word 17 cm high x 12 cm wide, you would only be able to get a three or at the most four characters on a sheet of A4, and that’s assuming you changed the orientation to landscape and made the margins as narrow as possible. Or are you printing on A3?

                            However, as far as text height goes, this can be done. For convenience, could we change into imperial rather than metric format? There is a cogent argument for doing this: a font size of 72 pt is exactly one inch in height. So convert 17 cm to inches — it’s around 6.7 — and multiply 72 by this figure. Make your font size, say, 482 pt. You will have to experiment to find a font that will result in a width of 12 cm because they have different thicknesses.`,
                    }, 
                    {
                        lngId: 2,
                        contentId: 1,
                        title: "RU: Genie Web",
                        previewDesc: "RU: previewDesc: Any text here",
                        description: "RU: description: Any text here",
                    },
                    {
                        lngId: 3,
                        contentId: 1,
                        title: "AM: Genie Web",
                        previewDesc: "AM: previewDesc: Any text here",
                        description: "AM: description: Any text here",
                    }
                ]
            },
            {
                id: 2,
                name: "About US",
                type: "firstPage",
                icon: "",
                translations: [
                    {
                            lngId: 1,
                            contentId: 2,
                            title: "EN: Inovation Hub",
                            previewDesc: "EN: previewDesc: Any text here",
                            description: "EN: description: Any text here",
                    }, 
                    {
                        lngId: 2,
                        contentId: 2,
                        title: "RU: Inovation Hub",
                        previewDesc: "RU: previewDesc: Any text here",
                        description: "RU: description: Any text here",
                    },
                    {
                        lngId: 3,
                        contentId: 2,
                        title: "AM: Inovation Hub",
                        previewDesc: "AM: previewDesc: Any text here",
                        description: "AM: description: Any text here",
                    }
                ]
            }
        ],
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getContent.fulfilled, (state, action) => {
            state.all = action.payload
        })
    }
} )

export const {} = content.actions
export default content.reducer